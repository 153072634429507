import axios, { AxiosResponse } from 'axios';

import { getAxiosConfigFromNext } from '@/utils/others';
import { getBackendURL } from '@/utils/urlUtils';

export const getCurrentUserInfo = () => {
    const config = getAxiosConfigFromNext();
    return axios.get(`${getBackendURL(false)}/api/v1/user/me`, config).then((res) => res.data);
};

export const getUserOpenPositions = () => {
    const config = getAxiosConfigFromNext();
    return axios.get(`${getBackendURL(false)}/api/v1/user-signals/open-positions`, config).then((res) => res.data);
};

export const getUserSubscription = () => {
    const config = getAxiosConfigFromNext();
    return axios.get(`${getBackendURL(false)}/api/v1/user-subscriptions`, config).then((res) => res.data);
};

export const getUserActions = (config?: any) => {
    const finalConfig = config || getAxiosConfigFromNext();
    return axios.get(`${getBackendURL(false)}/api/v1/user-action/pending?recents=true&limit=200`, finalConfig).then((res) => res.data);
};

export const getModelUserSubscription = (uuid: string) => {
    const config = getAxiosConfigFromNext();
    return axios.get(`${getBackendURL(false)}/api/v1/user-subscriptions/${uuid}`, config).then((res) => res.data);
};

export const removeUserModelSubscription = async (subscription: any) => {
    const config = getAxiosConfigFromNext();
    const url = `${getBackendURL(false)}/api/v1/user-subscriptions`;
    const { data } = await axios.put<AxiosResponse<any>>(url, subscription, config);
    return data;
};

export const subscribeToModel = (modelId: string) => {
    const config = getAxiosConfigFromNext();
    const url = `${getBackendURL(false)}/api/v1/user-subscriptions`;
    return axios.post(url, { modelId }, config);
};
