export enum EventNames {
    ERROR = 'error',
    WIDGET_IMPRESSION = 'WIDGET_IMPRESSION',
    SWIPED_BUTTON = 'swiped_button',
    SCREEN_VIEW = 'screen_view',
    CLICKED_BUTTON = 'clicked_button',
    CLICKED_STORY = 'clicked_story',
    LOGOUT = 'logout',
    VALIDATION_FAILURE = 'validation_failure',
    REFRESH = 'refresh',
    CLICKED_CUT = 'clicked_cut',
    DETAILS_ADDED = 'details_added',
    DETECTED_DYNAMIC_LINK = 'detected_dynamic_link',
    DETECTED_AF_ONELINK = 'detected_af_onelink',
    DETECTED_AF_DEEPLINK = 'detected_af_deeplink',
    CLICKED_HYPERLINK = 'clicked_hyperlink',
    API_FAILURE = 'api_failure',
    VERIFICATION_FAILURE = 'verification_failure',
    LOGIN = 'login',
    SIGNUP = 'signup',
    CLICKED_MODEL = 'clicked_model',
    SUBSCRIBED = 'subscribed',
    UNSUBSCRIBED = 'unsubscribed',
    TRACKING = 'tracking',
    TRADING = 'trading',
    TRACKING_FAILURE = 'tracking_failure',
    TRADING_FAILURE = 'trading_failure',
    FORM_FILL = 'form_fill',
    APP_TRACKING = 'app_tracking',
    STOCK_FILTERS = 'clicked_stock_card_filter',
    STOCK_DETAILS_PAGE_VIEW = 'stock_screen_viewed',
    MODEL_PILLS_CLICKED = 'clicked_stock_screen_model_pill',
    STOCK_CARD_CLICKED = 'clicked_stock_card',
    CLICKED_NEWS_ARTICLE = 'clicked_news_article',
    BROKER_LOGIN_FROM_APP = 'broker_login_app',
    CLICKED_PROMOTIONAL_CAROUSEL = 'clicked_promotional_carousel',
    INVITE_SHARED = 'invite_shared',
    QUIZ_ANSWERED = 'quiz_answered',
    QUIZ_COMPLETED = 'quiz_completed',
    RESOLVE_DYNAMIC_LINK = 'resolve_dynamic_link',
    SCREENSHOT_CAPTURED = 'screenshot_captured',
    DETAILS_UPDATED = 'details_updated',
    BROKER_LINKED = 'broker_linked',
    TRADING_WITHOUT_READ = 'trading_without_read'
}

export enum EventProperty {
    SUBSCRIPTION_TYPE = 'SUBSCRIPTION_TYPE',
    STEP = 'STEP',
    IS_AMO = 'is_amo',
    TRADING_TYPE = 'trading_type',
    LIFECYCLE_COUNT = 'lifecycle_count',
    EXECUTION_TYPE = 'execution_type',
    PARTIALLY_EXECUTED = 'partially_executed',
    IS_RETRY_ORDER = 'is_retry_order',
    INVESTED_AMOUNT = 'invested_amount',
    BUTTON_TYPE = 'button_type',
    IS_DEBUG_APP = 'IS_DEBUG_APP',
    FAILURE_ID = 'failure_id',
    E_MESSAGE = 'e_message',
    E_TAG = 'e_tag',
    SCREEN_NAME = 'screen_name',
    FROM_SCREEN = 'from_screen',
    ACCESS_TYPE = 'access_type',
    BUTTON_ID = 'button_id',
    CUT_NAME = 'cut_name',
    CUT_UNIQUE_KEY = 'cut_unique_key',
    MODEL_NAME = 'model_name',
    STORY_NAME = 'story_name',
    TIME_PERIOD = 'time_period',
    ACTION_COUNT = 'action_count',
    ACTION_UUID = 'action_uuid',
    MODEL_UNIQUE_KEY = 'model_unique_key',
    INSTRUMENT = 'instrument',
    EXECUTION_TIME = 'execution_time',
    URL = 'url',
    NAME = 'name',
    ERROR_MESSAGE = 'error_message',
    PHONE_NUMBER = 'phone_number',
    EMAIL = 'email',
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
    UTM_CONTENT = 'utm_content',
    DEEP_LINK_VALUE = 'deep_link_value',
    UUID = 'uuid',
    EVENT_TYPE = 'event_type',
    API_ID = 'api_id',
    SECTION = 'section',
    BUTTON_TEXT = 'button_text',
    TRADING_SYMBOL = 'trading_symbol',
    POSITION_TYPE = 'position_type',
    FORM_ID = 'form_id',
    STATUS = 'status',
    TYPE = 'type',
    VALUE = 'value',
    MODEL_LIST = 'model_list',
    STOCK_NAME = 'stock_name',
    HOLDING = 'holding',
    TIME_WINDOW = 'time_window',
    INSIGHTS = 'insights',
    TAB_NAME = 'tab_name',
    PREV_VALUE = 'previous_value',
    TRIGGERED_EXECUTION_FLOW = 'triggered_execution_flow',
    TARGET_TYPE = 'target_type',
    OPEN = 'open',
    CLOSE = 'close',
    ANALYSIS = 'analysis',
    ARTICLE_TITLE = 'article_title',
    ORGANISATION = 'organization',
    POSTED_AT = 'posted_at',
    RATING = 'rating',
    INVEST_MORE = 'invest_more',
    CURRENT_BALANCE = 'current_balance',
    LIFETIME_BALANCE = 'lifetime_balance',
    STATE = 'state',
    REFERRED_SIGNUP_COUNT = 'referred_signup_count',
    REFERRED_TRADE_COUNT = 'referred_trade_count',
    CHANNEL = 'channel',
    REFERRER = 'referrer',
    COIN_EARNED = 'coins_earned',
    QUESTION = 'question',
    ANSWER = 'answer',
    FIRST_TRADE = 'first_trade',
    ACTION_TYPE = 'action_type',
    VARIETY = 'variety',
    STEADY_STATE = 'steady_state',
    ZERO_STATE = 'zero_state',
    ACTIONABLE_BOOLEAN = 'actionables_boolean',
    LIVE_SIGNALS = 'number_of_signals_live',
    INVESTED = 'invested',
    CURRENT = 'current',
    RETURN = 'return',
    RETURN_PERCENTAGE = 'return_percentage',
    POSITION_RETURNS = 'positions_return_percentage',
    PRIMARY_URL = 'primary_url',
    SECONDARY_URL = 'secondary_url',
    SIGNAL_TYPE = 'signal_type',
    SIGNAL_USER_STATE = 'signal_user_state',
    IS_PARTIAL_ORDER = 'is_partial_order',
    SIGNAL_DATE = 'SIGNAL_DATE',
    VIEW_TYPE = 'view_type',
    ONBOARDING_SCREEN_ORDER = 'onboarding_screen_order',
    INTERACTION_TYPE = 'interaction_type',
    ONBOARDING_SCREEN_HEADER = 'onboarding_screen_header',
    LANGUAGE_SELECTED = 'language_selected',
    KNOWN_USER = 'known_user',
    BROKER_TYPE = 'broker_type',
    BROKER_NAME = 'broker_name',
    VIDEO_TITLE = 'video_title',
    VIDEO_ORDER = 'video_order',
    EXPLAINER_STORIES_HIDDEN = 'explainer_stories_hidden',
    CANCEL_SL_PENDING = 'cancel_sl_pending',
    EXIT_TYPE = 'exit_type',
    ENTRY_ORDER_TYPE = 'entry_order_type',
    PREVIOUS_BROKER = 'previous_broker',
    CHANGE_OR_ADD = 'change_or_add',
    PLATFORM = 'platform'
}

export enum UserProperties {
    EMAIL = 'email',
    MIXPANEL_EMAIL = '$email',
    MIXPANEL_NAME = '$name',
    PHONE_NUMBER = 'phone_number',
    NAME = 'name',
    PLATFORM = 'platform',
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
    UTM_CONTENT = 'utm_content',
    APP_TRACKING_PERMISSION = 'app_tracking_permission',
    EXTERNAL_STORAGE_PERMISSION = 'external_storage_permission',
    REFERRER = 'referrer',
    CURRENT_MINTCOINS = 'current_balance',
    LIFETIME_BALANCE = 'lifetime_balance'
}

export enum ButtonId {
    SUBSCRIBE_STEP_CONTINUE = 'SUBSCRIBE_STEP_CONTINUE',
    UPDATE_PREFERENCES = 'update_preferences',
    STARTED_LOGIN = 'started_login',
    GET_OTP = 'get_otp',
    RESEND_OTP = 'resend_otp',
    EDIT_PHONE_NUMBER = 'edit_phone_number',
    COIN_LEDGER = 'COIN_LEDGER',
    OPEN_SIGNALS = 'open_signals',
    OPEN_POSITIONS = 'open_positions',
    OPEN_GLOSSARY = 'open_glossary',
    OPEN_DASHBOARD_TABS = 'open_dashboard_tabs',
    ORDER_EXECUTION_FLOW = 'order_execution_flow',
    UNSUBSCRIBE_MODAL = 'unsubscribe_modal',
    UNSUBSCRIBE = 'unsubscribe',
    CONTACT_US = 'CONTACT_US',
    SUBSCRIBE = 'subscribe',
    GLOSSARY = 'glossary',
    EXPAND_SIGNAL = 'expand_signal',
    VERIFY_OTP = 'verify_otp',
    INVITE = 'invite',
    SHARE = 'share',
    EXPLAINER_TILE = 'explainer_tile',
    SIGNAL_TIMELINE = 'signal_timeline',
    ALL_TRADES = 'all_trades',
    LATEST_CLOSED_TRADES = 'latest_closed_trades',
    MODEL_FACTSHEET = 'model_factsheet',
    DOWNLOAD_FACTSHEET = 'download_factsheet',
    FACTSHEET_PAGE = 'factsheet_page',
    CHANGE_QUANTITIES = 'change_quantities',
    CHANGE_PREFERRED_AMOUNT = 'change_preferred_amount',
    TRADE = 'trade',
    TRADE_TRY_AGAIN = 'trade_try_again',
    TRACK = 'track',
    PROFILE = 'profile',
    ADD_DETAILS = 'add_details',
    UPDATE_DETAILS = 'update_details',
    MODEL_ACTIONABLE = 'model_actionable',
    REQUEST_MODEL_ACCESS = 'request_model_access',
    RENEW_MODEL_ACCESS = 'renew_model_access',
    EXTEND_MODEL_ACCESS = 'extend_model_access',
    STOCK_DETAILS_TAB = 'stock_details_tab',
    STOCK_SCREEN_TRADE_BUTTON = 'stock_screen_invest_via',
    VIEW_STOCK_DETAILS = 'view-stock-details-page',
    DISCOVER_SCREEN = 'view-discover-screen',
    DONT_SHOW_EXPLAINER_CARD = 'dont_show_explainer_cards',
    JOIN_DISCUSSION_EXPLAINER_CARD = 'join_discussion_explainer_cards',
    EXPLAINER_GO_TO_MODEL_LIST = 'explainer_model_list',
    EXPLAINER_SEND_ME_A_SIGNAL = 'explainer_signal_send_signal',
    EXPLAINER_JOIN_COMMUNITY = 'explainer_join_community',
    EXPLAINER_KNOW_MORE = 'explainer_model_know_more',
    EXPLAINER_INVEST_BUTTON = 'explainer_model_invest_now',
    TUTORIAL_CARDS = 'tutorial_cards',
    MODEL_COMPARISON_TABLE = 'model_comparison_table',
    ACTIONABLE_SIGNALS = 'user_actionable_signals',
    JOIN_MEMBER_COMMUNITY = 'open_join_telegram_community',
    LONG_INDIA_CARD_SUBSCRIBE = 'long_india_card_subscribe',
    LONG_INDIA_CARD_INVEST = 'long_india_card_invest_100',
    DONT_SHOW_EXPLAINER_CARD_ON_EXECUTION_FLOW = 'dont_show_explainer_cards_on_execution_screen',
    EXPLAIN_ON_EXECUTION_FLOW = 'explainer_on_execution_flow',
    CONVERT_TO_TRADING = 'convert_to_trading',
    STOCK_CARD_FILTERS = 'stock_card_filters',
    STOCK_CARD_SORTING = 'stock_card_sorting',
    OTHER_STOCKS = 'others_stocks_icons',
    MODEL_PILLS_CLICKED = 'model_pills_clicked_stock_details',
    IN_APP_REVIEW_SUBMIT = 'in_app_review_submit',
    IN_APP_REVIEW_CANCELLED = 'in_app_review_cancelled',
    INVEST_MORE = 'invest_more',
    OPEN_REFERRAL_SCREEN = 'open_referrals_screen',
    OPEN_EARNING_SCREEN = 'open_earnings_screen',
    OPEN_REFERRAL_FAQ = 'open_referrals_FAQ',
    OPEN_REFERRAL_SUPPORT = 'open_referrals_support',
    OPEN_REFERRAL_TnC = 'open_referrals_tnc',
    OPEN_EARNING_FAQ = 'open_earnings_FAQ',
    OPEN_EARNING_SUPPORT = 'open_earnings_support',
    OPEN_EARNING_TnC = 'open_earning_tnc',
    OPEN_REFERRALS_BOTTOM_SHEET = 'open_referrals_bottom_sheet',
    OPEN_REWARDS_BOTTOM_SHEET = 'open_rewards_bottom_sheet',
    OPEN_REFERRAL_FLOW = 'open_referral_flow',
    OPEN_DISCOVER_SCREEN = 'open_discover_screen',
    OPEN_PROFILE_SCREEN = 'open_profile_screen',
    OPEN_COIN_LEDGER_SCREEN = 'open_coins_ledger_screen',
    OPEN_MINT_COIN_TUTORIAL_BOTTOM_SHEET = 'open_mint_coin_tutorial_bottom_sheet',
    OPEN_SIGNAL_DISMISSAL_POPUP = 'open_signal_dismissal_popup',
    CLOSE_SIGNAL_DISMISSAL_POPUP = 'close_signal_dismissal_popup',
    SIGNAL_DISMISSAL_POPUP = 'signal_dismissal_popup',
    OPEN_MODEL_SCREEN = 'open_model_screen',
    OPEN_HOME_SCREEN = 'open_home_screen',
    DISMISS_NOTICE_BUTTON = 'dismiss_notice_board',
    OPEN_DASHBOARD_SIGNALS = 'open_dashboard_signals',
    OPEN_DASHBOARD_PNL = 'open_dashboard_pnl',
    OPEN_MODEL_EXPLAINER_VIDEO = 'open_model_explainer_video',
    DISMISS_MODEL_EXPLAINER_VIDEO = 'dismiss_model_explainer_video',
    DISMISS_SIGNAL = 'dismiss_signal',
    OPEN_PAST_TRADES_SCREEN = 'open_past_trades_screen',
    OPEN_PNL_BOTTOM_SHEET = 'open_pnl_dashboard_bottom_sheet',
    EXIT_POSITIONS_MANUALLY = 'exit_positions_manually',
    LANGUAGE_CHANGE = 'changed_language',
    OPEN_NEXT_PRE_ONBOARDING_SCREEN = 'open_next_pre_onboarding_screen',
    OPEN_LOGIN_SCREEN = 'open_login_screen',
    OPEN_POST_ONBOARDING_SCREEN = 'open_post_onboarding_screen',
    OPEN_PREVIOUS_POST_ONBOARDING_SCREEN = 'open_previous_post_onboarding_screen',
    OPEN_NEXT_POST_ONBOARDING_SCREEN = 'open_next_post_onboarding_screen',
    PHONE_TEXT_FIELD = 'phone_text_field',
    NAME_TEXT_FIELD = 'name_text_field',
    EMAIL_TEXT_FIELD = 'email_text_field',
    SHOW_INVITE_LINK_FIELD = 'show_invite_link_field',
    OPEN_BROKER_SCREEN = 'open_broker_screen',
    OPEN_ADD_BROKER_FLOW = 'open_add_broker_flow',
    OPEN_BROKER_FLOW = 'open_broker_flow',
    OPEN_CHANGE_BROKER_FLOW = 'open_change_broker_flow',
    EXPLAINER_MODEL_LIST = 'explainer_model_list',
    OPEN_EXPLAINER_VIDEO = 'open_explainer_video',
    OPEN_POST_LOGIN_ONBOARDING = 'open_post_login_onboarding_screen',
    OPEN_CANCEL_SL_FLOW = 'open_cancel_sl_flow',
    OPEN_READ_ACCESS_BOTTOM_SHEET = 'open_read_access_bottom_sheet',
    OPEN_READ_ACCESS_FLOW = 'open_read_access_flow',
    OPEN_SL_CANCEL_BOTTOM_SHEET = 'open_cancel_sl_bottom_screen',
    OPEN_SL_CANCEL_FLOW = 'open_cancel_sl_flow',
    DISMISS_MANUAL_TRADE_BOTTOM_SHEET = 'dismiss_manual_trade_bottom_sheet',
    OPEN_MANUAL_TRADE_BOTTOM_SHEET = 'open_manual_trade_bottom_sheet',
    OPEN_EXIT_CONFIRMATION_BOTTOM_SHEET = 'open_exit_confirmation_bottom_screen',
    DISMISS_EXIT_CONFIRMATION_BOTTOM_SHEET = 'dismiss_exit_confirmation_bottom_screen',
    NOTIFY_REWARDS_LAUNCH = 'notify_rewards_launch'
}

export enum InputTypes {
    PHONE_NUMBER = 'phone_number',
    OTP = 'OTP'
}

export enum EventType {
    TRIGGER_EVENT = 'trigger_event',
    CONVERSION_EVENT = 'conversion_event',
    FAILURE_EVENT = 'failure_event'
}

export enum ApiId {
    GET_OTP = 'get_otp',
    STORY = 'story'
}

export enum FormId {
    MODEL_SUBSCRIPTION = 'model_subscription'
}

export enum EventValues {
    LONG_INDIA_INGRESS = 'Long India Ingress',
    ACTIONABLES = 'Your Actionables',
    ACTIONABLES_TAB = 'Actionables',
    WATCHLIST = 'Watchlist',
    BOTTOM_BAR = 'Bottom Nav',
    YOUR_PNL = 'Your P&L',
    DO_MORE = 'Do More',
    START_TRADING_JOURNEY = 'Start your trading journey',
    EXPLORE_MODEL_CARDS = 'Explore Models Card',
    ORDER_BOTTOM_SHEET = 'Order Bottom Sheet',
    EXIT_CONFIRMATION_BOTTOM_SHEET = 'Exit Confirmation Bottom Sheet',
    STORY_CLICK = 'story_click',
    CLICK = 'click',
    STEADY_STATE = 'steady_state',
    ZERO_STATE = 'zero_state',
    CNC = 'CNC',
    INTRADAY = 'INTRADAY',
    EXPLAINER_CARDS = 'Explainer cards',
    POPULAR_MODELS_SECTION = 'Popular models to get started',
    COIN_PILL = 'Coins Pill',
    MINT_CLUB_BANNER = 'Mint Club Banner',
    ANDROID_POP_UP = 'android_pop_up',
    READ_ACCESS = 'read_access',
    WRITE_ACCESS = 'write_access',
    BOTTOM_SHEET = 'bottom_sheet',
    DISCRETIONARY = 'discretionary',
    POST_SIGNAL_EXIT = 'post_exit_signal',
    BROKER_ORDER = 'broker',
    MANUAL_ORDER = 'manual',
    REWARDS_BOTTOM_SHEET = 'Rewards Bottom Sheet',
    REWARDS_BANNER = 'Rewards Banner',
    CHANGE = 'change',
    ADD = 'add',
    GLOBAL_BANNER = 'global_banner',
    WEB = 'web'
}
