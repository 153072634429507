/* Constants */
import {ENTITY_ASSET_URL, SHAREABLE_MEDIUMS} from 'Constants/constants'

/* Types */
import {API_CONFIG, IConfig} from 'Types/api'

const baseUrls = {
    [API_CONFIG.STAGING]: {
        GET_API_URL: 'https://staging-api.investmint.club',
        NON_GET_API_URL: 'https://staging-api.investmint.club'
    },
    [API_CONFIG.PRODUCTION]: {
        GET_API_URL: 'https://api-cache.investmint.club',
        NON_GET_API_URL: 'https://api.investmint.club'
    }
}

export const config: IConfig = {
    baseUrls,
    changeEnv: env => {
        if (env) {
            config.currentEnv = env
        } else {
            config.currentEnv = config.defaultEnv
        }
    },
    defaultEnv: API_CONFIG.PRODUCTION,
    currentEnv: API_CONFIG.PRODUCTION,
    getAPIHostUrl() {
        return this.baseUrls[this.currentEnv]
    }
}

export const getAPIHostUrl = (isNonGetCall = false): string => {
    return config.getAPIHostUrl()?.[
        isNonGetCall ? 'NON_GET_API_URL' : 'GET_API_URL'
    ]
}

export const getSharingHostUrl = () => process.env.NEXT_PUBLIC_SHARING_HOST_URL
export const getAPIKey = () => 'yLpum7YLhC9aYsPs'
export const decodeUrlLink = signalLink => JSON.parse(atob(signalLink))

export const encodeUrlLink = signalObj =>
    btoa(encodeURIComponent(JSON.stringify(signalObj)))

export const getEntityImageUrl = (isin: string) =>
    `${ENTITY_ASSET_URL}/${isin}.png`

export const getRedirectionUrlBasedOnPageType = (
    pageType: string,
    props: any
) => {
    switch (pageType) {
        case 'SignalDetailsPage': {
            const {id} = props
            return `${encodeURIComponent(`/signal/${id as string}`)}`
        }
        default: {
            return '/login'
        }
    }
}

export const getUTMRichUrls = (baseUrl: string, medium: string) => {
    let utmQueries: string
    switch (medium) {
        case SHAREABLE_MEDIUMS.WHATSAPP: {
            utmQueries =
                'utm_source=whtsp&utm_medium=ToolBox&utm_id=beta_1.0utm_campaign=aquisiton'
            break
        }
        case SHAREABLE_MEDIUMS.TWITTER: {
            utmQueries =
                'utm_source=twt&utm_medium=ToolBox&utm_id=beta_1.0utm_campaign=aquisiton'
            break
        }
        default: {
            utmQueries =
                'utm_source=gnr&utm_medium=ToolBox&utm_id=beta_1.0utm_campaign=aquisiton'
            break
        }
    }
    return `${baseUrl}?${utmQueries}`
}

export const getQueryFromObject = (data?: object) =>
    data && Object.keys(data).length
        ? Object.keys(data)
              ?.map(key => data[key] && `${key}=${String(data[key])}&`)
              ?.join('')
        : ''

export const getMediaUrlFormatBasedOnPlatform = (url: string, format: string) =>
    `${url?.substring(0, url?.lastIndexOf('.'))}.${format}`

export const getParameterFromUrl = (url: string, param: string) => {
    const regex = new RegExp('.*[?&]' + param + '=([^&]+)(&|$)')
    const match = url.match(regex)
    return match ? match[1] : ''
}
