import mixpanel from 'mixpanel-browser';
import { EventProperty, EventType, EventValues } from 'AnalyticsTypes/events';

const superProperties = { platform: 'web', IS_DEBUG: false };

export const trackEvent = (eventName: string, properties?: any) => {
    if (!(mixpanel as any)['__loaded']) return;
    mixpanel.track(eventName, {
        ...properties,
        ...superProperties,
        [EventProperty.EVENT_TYPE]: EventType.CONVERSION_EVENT,
    });
};

export const trackConversionEvent = (eventName: string, properties?: any) => {
    if (!(mixpanel as any)['__loaded']) return;
    mixpanel.track(eventName, { ...properties, ...superProperties });
};

export const trackFailureEvent = (eventName: string, properties?: any) => {
    if (!(mixpanel as any)['__loaded']) return;
    mixpanel.track(eventName, {
        ...properties,
        ...superProperties,
        [EventProperty.EVENT_TYPE]: EventType.FAILURE_EVENT,
    });
};

export const trackTriggerEvent = (eventName: string, properties?: any) => {
    if (!(mixpanel as any)['__loaded']) return;
    mixpanel.track(eventName, {
        ...properties,
        ...superProperties,
        [EventProperty.EVENT_TYPE]: EventType.TRIGGER_EVENT,
    });
};

export class Tracking {
    static initMixpanel() {
        mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY || 'd250e53022809bd0ec1a6c52e5ed57a6', { debug: true });
    }

    static trackEvent(eventName: string, properties: any) {
        if (!(mixpanel as any)['__loaded']) return;
        mixpanel.track(eventName, { ...properties, ...superProperties });
    }

    static identifyUser(userId: string) {
        if (!(mixpanel as any)['__loaded']) return;
        mixpanel.identify(userId);
    }
}

export const EVENT_TYPE = {
    BUTTON_CLICKED: 'clicked_button',
    SCREEN_VIEWED: 'screen_view',
    MODEL_OPEN: '',
    GET_OTP: 'get_otp',
    LOGIN: 'login',
    LOGOUT: 'logout',
    CLICKED_MODEL: 'clicked_model',
    UNSUBSCRIBED: 'unsubscribed',
    SUBSCRIBED: 'subscribed',
    TRACKING: 'tracking',
    TRADING: 'trading',
};
