/* Types */
import {InputType} from 'Types/inputType'
import {EXECUTION_TYPE} from 'Types/subscriptions'

/* Typography */
import colors from 'Typography/colors'

/* Types */
import {LifeCycleState} from 'Types/actions'
import {ModelEnums} from 'Types/model'
import {MintCoinPurpose} from 'Types/mintCoin'
import {ONBOARDING_LANGUAGES} from 'Types/gen'

export const DELIMITER = '.'

export const CLOUDINARY_CLOUD_NAME = 'invesmint'

export const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/upload`

export const CLOUDINARY_UPLOAD_PRESET = 'live_share_signal'

export const CLOUDINARY_FOLDER = 'shared-live-signals'
export const CLOUDINARY_SCREENSHOTS_FOLDER = 'screenshots'

export const REFERRAL_CODE = 'referralCode'
export const REFERRER = 'referrer'
export const REFERRAL_TYPE = 'referralType'

export const PAYMENT_REF_ID = 'ref_id'
export const STATUS = 'status'

export const ENTITY_ASSET_URL = `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/stocks-assets/logos`

export const INVESTMINT_WEBSITE_URL = 'https://www.investmint.club'

export const CONTACT_US_EMAIL = 'hi@investmint.club'
export const SUPPORT_EMAIL = 'support@investmint.club'
export const FEEDBACK_FORM_LINK = 'https://tally.so/r/w2jrjg'
export const SUPPORT_AND_FAQ_LINK = 'https://support-widget.onrender.com/'
export const JOIN_COMMUNITY_LINK = 'https://telegram.me/investmintclub'

export const PAYMENT_REDIRECT_URL = 'https://investmint.club'
export const BROKER_LOGIN_REDIRECT_URL = 'investmint.club/broker'
export const BROKER_AFTER_ORDER_REDIRECT_URL = 'investmint.club/broker'

export const headerDimensions = {
    LOGO: {
        width: 34.53,
        height: 16
    },
    SYMBOL: {
        width: 141.1368421053,
        height: 16
    }
}

export const ACCESS_TOKEN = 'access-token'
export const AUTH_TOKEN = 'authToken'
export const PHONE_NUMBER_KEY = 'phone-number'

export const PHONE_NUMBER_ERROR = 'Enter a valid 10 digit mobile number'
export const USER_NAME = 'username'
export const OPT_LENGTH = 4

export const OTP_INVALID_ERROR = 'Your OTP is incorrect, try again.'

export const INDIAN_COUNTRY_CODE = '+91'

export const CURRENCY_SYMBOL = '₹'

export const INITIAL_COUNT_DOWN = 30

export const KITE_PUBLISHER_SRC = 'https://kite.trade/publisher.js?v=3'

export const MAX_WIDTH_SUPPORTED = 400

export const FUTURES_KEY = 'FUT'

export const USER_ID = 'user-id'

export const NAME_ERROR_TEXT = 'Enter Your Name'

export const EMAIL_ERROR_TEXT = 'Enter valid email address'

export const AGE_ERROR_TEXT = 'Select Your Age'

export const API_KEY = 'X-IM-API-KEY'

export const STRATEGY_TYPE = {
    SINGLE_INSTRUMENT: 'Single Instrument Strategy',
    MULTIPLE_INSTRUMENT: 'Multiple Instrument Strategy'
}

export const NIFTY = 'nifty'

export const PAGE_TYPE = {
    SIGNAL_DETAILS: 'Signal Details',
    ALL_SIGNALS: 'All Signals'
}

export const MODAL_TYPES = {
    LOGIN: 'Login',
    OTP: 'Otp',
    EMAIL: 'Email',
    FILTERS: 'Filters'
}

export const USER_AUTH_STAGE = {
    MOBILE: 'MOBILE',
    OTP: 'OTP',
    EMAIL: 'EMAIL'
}

export const DISCLAIMER_MODAL_INFO = {
    type: 'Disclaimer',
    heading: 'Disclaimer',
    description:
        'This signal is shared solely for educational purposes only and should not in any form be treated as “Investment advice” as Investmint (Toolbox Technologies Pvt Ltd) is not a SEBI registered advisor. The button to execute the trade with your broker is provided for convenience only.',
    ctaText: 'View Signal'
}

export const NON_INVITES_USER_MODAL = {
    type: 'Non Invited User',
    heading: 'Welcome to Investmint!',
    description:
        'Investmint is in early beta. We have added you to our early access list. You will receive your invite on your email id soon.',
    body: 'You can fast-track your access by hitting the button below and tweeting about us.',
    ctaText: 'I want priority access'
}

export const NON_INVITED_USERS_TWITTER_LINK = (url: string) =>
    `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        `Just discovered this Signal on @investmintclub  ${
            url.startsWith('https') ? url : `https://${url}`
        } \n \nData backed market opportunities — looks promising.\n \n#investmint #imint #productlaunch`
    )}`

export const INVITED_USER_CHECK_EMAIL_DISCLAIMER = {
    type: 'Invited User',
    heading: `You've been Invited!`,
    description: `Check your registered email ID and accept the invite to start minting!`
}

export const MINIFY_URL_LINK = 'https://api.rebrandly.com/v1/links'

export const BROKER_INFORMATION_FORM_LINK =
    'https://z4p1oat3wtt.typeform.com/to/v9vxJpUV'

export const TERMS_AND_CONDITIONS_PAGE_LINK =
    'https://www.investmint.club/terms-conditions'

export const SHAREABLE_MEDIUMS = {
    WHATSAPP: 'WHATSAPP',
    GENERIC: 'GENERIC',
    TWITTER: 'TWITTER'
}

export const FILTER_STATES = {
    ALL: 'ALL',
    NEW_AND_OPEN: 'NEW_AND_OPEN',
    ACTIVE: 'ACTIVE',
    EXPIRED: 'EXPIRED',
    EQUITY: 'EQUITY',
    DERIVATIVES: 'DERIVATIVES'
}

export const FILTER_TYPE = {
    STATE: 'State',
    ASSET_CLASS: 'Asset class'
}

export const FILTER_NAMES = {
    [FILTER_STATES.ALL]: 'All',
    [FILTER_STATES.NEW_AND_OPEN]: 'New and Open',
    [FILTER_STATES.ACTIVE]: 'Active',
    [FILTER_STATES.EXPIRED]: 'Expired',
    [FILTER_STATES.EQUITY]: 'Equity',
    [FILTER_STATES.DERIVATIVES]: 'Derivative'
}

export const filtersOptions = [
    {
        heading: FILTER_TYPE.STATE,
        type: InputType.RADIO,
        options: [
            {
                label: FILTER_NAMES[FILTER_STATES.ALL],
                id: FILTER_STATES.ALL
            },
            {
                label: FILTER_NAMES[FILTER_STATES.NEW_AND_OPEN],
                id: FILTER_STATES.NEW_AND_OPEN
            },
            {
                label: FILTER_NAMES[FILTER_STATES.ACTIVE],
                id: FILTER_STATES.ACTIVE
            },
            {
                label: FILTER_NAMES[FILTER_STATES.EXPIRED],
                id: FILTER_STATES.EXPIRED
            }
        ]
    },
    {
        heading: FILTER_TYPE.ASSET_CLASS,
        type: InputType.CHECKBOX,
        options: [
            {
                label: FILTER_NAMES[FILTER_STATES.EQUITY],
                id: FILTER_STATES.EQUITY
            },
            {
                label: FILTER_NAMES[FILTER_STATES.DERIVATIVES],
                id: FILTER_STATES.DERIVATIVES
            }
        ]
    }
]

export const TAG_NAMES = {
    INTRADAY: 'INTRADAY'
}

export const PRODUCT_TYPE = {
    MIS: 'MIS',
    CNC: 'CNC'
}

export const ORDER_TYPE = {
    MARKET: 'MARKET',
    SLM: 'SL-M',
    SL: 'SL'
}

export const EXIT_REASONS_CONTENT = {
    MIS: 'Margin Intraday Square-Off',
    SL: 'Stop Loss Hit',
    TSL: 'Trailing Stop Loss Hit',
    TGT: 'Target Hit',
    TTGT: 'Trailing Target Hit',
    RO: 'Rollover Trade',
    RB: 'Portfolio Rebalance',
    PC: 'Post-circuit Exit',
    TBE: 'Time-based Exit'
}

export const SMALLCASE_SDK_URL =
    'https://gateway.smallcase.com/scdk/2.0.0/scdk.js'

export const TRANSACTION_BUTTON_ID = 'transaction-button'

export const MODEL_PARAMETERS = {
    AVERAGE_WIN_RATE: {
        heading: 'Avg win-rate',
        colorBreakPoint: 60,
        showTrendIcon: false
    }
}

export const AVERAGE_WIN_RATE = 'AVERAGE_WIN_RATE'

export const SIGNALS_PAGE_TITLE = 'Investmint | All the Action'

export const ICON_PLACEMENT = {
    START: 'start',
    END: 'end'
}

export const MODEL_ATTRIBUTES = {
    AVERAGE_ANNUAL_RETURNS: {
        title: 'Average Annual Returns'
    },
    DRAW_DOWN: {
        title: 'Max drawdown'
    },
    CAPITAL_REQUIRED: {
        title: 'Capital allotted'
    },
    RESERVE: {
        title: 'Includes reserve for drawdowns'
    },
    CAPITAL_DEPLOYED: {
        title: 'Capital deployed'
    },
    DEPLOYMENT_TIME_PERIOD: {
        title: 'Money used to take positions'
    },
    SUBSCRIPTION: {
        title: 'Period tested'
    },
    RECOMMENDED_TIME: {
        title: 'Action frequency'
    }
}

export const MODEL_DETAILS_SECTION = {
    PERFORMANCE: {
        title: 'Performance'
    },
    ABOUT_MODEL: {
        title: 'About the model'
    },
    LIVE_TRADES: {
        title: 'Live Signal',
        notEnteredTitle: 'Open Signal',
        titleDesc: '% P/L',
        overallText: 'Overall',
        preEntryNudgeText:
            'Live returns will be visible once the average entry price for each stock is computed',
        postEntryNudgeText:
            'Live returns are computed with the initial average entry price of each stock and may differ for you based on when you enter'
    },
    TRADE: {
        title: 'Trade history',
        actionText: 'View all Signals',
        subHeading: 'Latest Signals',
        subHeadingDesc: '% P/L',
        nudgeText:
            'Returns are computed after deducting brokerage fees, GST, STT, and other trading costs'
    },
    MINT_LIBRARY: {
        title: 'Mint library'
    },
    CHART_DATETIME_SECTION: 'Data from',
    TOTAL_ACTIONS: 'Total actionables ',
    FETCHING_DATA: 'Fetching data...',
    SUBSCRIBE: 'Subscribe',
    FACT_SHEET: 'Fact-sheet',
    BACK_TEST: {
        heading: 'Past Performance',
        inputText: 'Inputs considered',
        report: 'Report'
    }
}

export const PERFORMANCE_TIME_YEAR = 4
export const YTD = 'YTD'
export const MAX = 'Since 2019'

export const defaultFactSheetInfo =
    'Our factsheet contains detailed information about the quant model, past performance, research, and more.'

export const MODAL_CONTENT_ID = 'modal-content'

export const CAROUSEL_TYPE = {
    VIDEO: 'video',
    IMAGE: 'image'
}

export const CAROUSEL_POSITION = {
    TOP: 'TOP',
    BOTTOM: 'BOTTOM'
}

export const LIST_TYPE = {
    ORDERED_LIST: 'ORDERED_LIST',
    DEFAULT_LIST: 'DEFAULT_LIST'
}

export const GLOSSARY_CONTENT = [
    {
        title: 'AAR (Average Annual Return)',
        description:
            'Average yearly return on capital, when a fixed base amount is deployed for each trade.'
    },
    {
        title: 'Average Loss',
        description:
            'Average percentage loss on the base invested amount, per losing trade.'
    },
    {
        title: 'Average Win',
        description:
            'Average percentage gain on the base invested amount, per winning trade.'
    },
    {
        title: 'Biggest Loss',
        description:
            'Highest percentage lost in a single trade - of all losing trades.'
    },
    {
        title: 'Biggest Win',
        description:
            'Highest percentage made in a single trade - of all winning trades.'
    },
    {
        title: 'CAGR',
        description:
            "The average growth rate of a portfolio’s value per year, if the model's returns were to be compounded over a larger time frame."
    },
    {
        title: 'Capital Deployed',
        description:
            "The capital value we invest per trade, spread evenly across the model's signals."
    },
    {
        title: 'Expectancy',
        description:
            'Equals to the total profits made divided by the total losses made. A higher expectancy value implies that, over time, the value of a model’s profits is greater than its losses.'
    },
    {
        title: 'Hedge Factor',
        description:
            'Level to which the portfolio is covered in cases of extreme market downturns.'
    },
    {
        title: 'Intraday',
        description:
            'Both actions of the model (buying and selling) happen within the same day. The trade gets completed before the market closes. In other words you book your profits or losses the same day, with no overnight open positions.'
    },
    {
        title: 'Max drawdown',
        description:
            'Difference between the maximum observed value of a portfolio and its subsequent minimum. However, a negative value does not always indicate that the portfolio is underwater.'
    },
    {
        title: 'Max losing streak',
        description:
            'Longest period (days/weeks/trades) of successive losses, without any win in between.'
    },

    {
        title: 'Max winning streak',
        description:
            'Longest period (days/weeks/trades) of successive wins, without any losses in between.'
    },
    {
        title: 'Minimum capital',
        description:
            'The base capital value we recommend per trade, including the investment amount as well as a buffer to cover for potential drawdowns.'
    },
    {
        title: 'Model',
        description:
            'A trading or investing model is a clearly defined, step-by-step rule-based structure for governing trading activities, which includes buy, sell, and rebalancing related rules.'
    },
    {
        title: 'Model Beta',
        description:
            "Compares a model's volatility with respect to that of the market as a whole. A Beta value greater (lesser) than 1 implies that it is more (less) volatile than the market."
    },
    {
        title: 'Monthly',
        description:
            'Models with a monthly holding period, stocks are bought at the beginning of the month, held the entire month and sold at the end of the same month.'
    },
    {
        tile: 'Overnight',
        description:
            "Models with an Overnight holding period, Stocks / Futures contracts are bought at day’s closing price and sold at next day's open."
    },
    {
        title: 'Portfolio',
        description:
            'A collection of financial instruments/assets put together - such as stocks, commodities, bonds and so on.'
    },
    {
        title: 'Positional',
        description:
            'Both actions of the model (buying and selling) happen on separate days. It could be from 1 day to many days.'
    },
    {
        title: 'Recovery time (Max drawdown)',
        description:
            "Days taken to recover back to the portfolio's peak value from its trough, in the case of maximum drawdown."
    },
    {
        title: 'Recovery time (Max loss streak)',
        description:
            'Days taken to recover the losses incurred to the portfolio during the maximum losing streak.'
    },
    {
        title: 'Sharpe Ratio',
        description:
            "Compares a model's excess return with the risk-free rate during the same time frame, relative to its volatility. Simply put, the model’s returns increase by this value for a unit increase in volatility levels."
    },
    {
        title: 'Subscription',
        description:
            'The minimum recommended subscription period to capture the model’s true performance.'
    },
    {
        title: 'Weekly',
        description:
            'Models with a weekly holding period, stocks are bought at the beginning of the week, held the entire week and sold at the end of the same week.'
    },
    {
        title: 'Win-rate',
        description:
            'The number of winning trades made by the model, divided by the total number of trades it makes. A higher win-rate implies that the model is on the right side of the trade on more instances than it is on the wrong side.'
    },
    {
        title: 'Volatility',
        description:
            'The rate at which an asset’s price fluctuates in a specified time frame.'
    }
]

export const welcomeScreenContent = {
    pitchText: 'Active investing, simplified',
    heading: 'Signal based actionable investing for smart investors',
    buttonText: 'Get started',
    tncText: "It'll take a couple of minutes. We promise."
}

export const LOGIN_TRUST_TYPE = {
    SAFE: 'SAFE',
    CONTROL: 'CONTROL',
    CREDENTIALS: 'CREDENTIALS'
}

export const loginScreenContent = {
    heading: 'Start here',
    buttonText: 'Continue',
    description: 'Enter your phone number to proceed',
    adminLoginText: 'Login as admin',
    getUpdates: 'Get updates on',
    whatsapp: 'Whatsapp',
    whatsappIcon:
        'https://res.cloudinary.com/invesmint/image/upload/v1678550034/assets/whatsapp_ldtikt.png',
    disclaimerText: [
        {
            text: 'By continuing you agree to the '
        },
        {
            text: 'terms & conditions',
            link: 'https://www.investmint.club/terms-conditions'
        },
        {
            text: ' and '
        },
        {
            text: 'privacy policy',
            link: 'https://www.investmint.club/privacy-policy'
        }
    ],
    trustElementsContent: [
        {
            title: 'Safe transactions',
            subHeading: 'Money stays in your broker account',
            type: LOGIN_TRUST_TYPE.SAFE
        },
        {
            title: 'You’re In control',
            subHeading: 'Sell anytime from your broker',
            type: LOGIN_TRUST_TYPE.CONTROL
        },
        {
            title: 'No credentials stored',
            subHeading: 'Your information is safe',
            type: LOGIN_TRUST_TYPE.CREDENTIALS
        }
    ]
}

export const phoneVerificationScreenContent = {
    heading: 'Verify your number',
    subHeading: 'Enter OTP received on',
    buttonText: 'Continue',
    didNotGetCode: "Didn't get the code?",
    resendNowText: 'Resend now',
    resendText: 'Resend',
    resendOTPSuccessText: 'Verification code resent successfully '
}

export const moreDetailsScreenContent = {
    heading: 'Welcome to Investmint!',
    updateHeading: 'Edit Profile',
    buttonText: 'Continue',
    updateText: 'Update',
    description: "Let's get started by adding your name and email address",
    emailLabel: 'Email address',
    nameLabel: 'Full name',
    referralLabel: 'Invite link (optional)',
    haveAReferCode: 'Have an invite link?',
    enterInviteCode: 'Eg. https://investmint.page.link/****',
    trustElementText: '100% encrypted and secure data.'
}

export const referralInviteBaseLink = 'https://investmint.page.link'

export const ACCESS_TOKEN_SERVICE = 'AccessTokenService'

/* TODO- can make a function later which takes a few inputs and set shadow */
export const shadow = {
    shadowColor: colors.BASE.PRIMARY,
    shadowOffset: {
        width: 4,
        height: 9
    },
    shadowOpacity: 0.08,
    shadowRadius: 11.95,

    elevation: 4
}

export const CONTACT_US = 'Contact us'
export const SHARE_FEEDBACK = 'Share feedback'
export const SUPPORT = 'Support'
export const LINK_BROKER_TEXT = 'Manage broker'
export const JOIN_COMMUNITY = 'Go to Mint Club'
export const EARNING_SECTION = 'Mint rewards'
export const REFERRAL_SECTION = 'Invite friends'

export const enum LINK_TYPE {
    WEB_VIEW,
    EXTERNAL,
    INTERNAL,
    EARNING,
    REFERRAL,
    LINK_BROKER
}

export const PROFILE_ITEMS = [
    {
        title: EARNING_SECTION,
        description: 'Get rewarded for your trust',
        type: LINK_TYPE.EARNING
    },
    {
        title: REFERRAL_SECTION,
        description: "Earn mintcoins on your friend's first three trades",
        type: LINK_TYPE.REFERRAL
    },
    {
        title: SHARE_FEEDBACK,
        description: 'We are all ears!',
        link: FEEDBACK_FORM_LINK,
        type: LINK_TYPE.WEB_VIEW
    },
    {
        title: JOIN_COMMUNITY,
        description: 'Interact, discuss and learn',
        link: JOIN_COMMUNITY_LINK,
        type: LINK_TYPE.EXTERNAL
    },
    {
        title: LINK_BROKER_TEXT,
        description: 'Manage your connected brokers for hassle-free trading',
        link: SUPPORT_AND_FAQ_LINK,
        type: LINK_TYPE.LINK_BROKER
    },
    {
        title: SUPPORT,
        description: 'Find answers to all your questions, or write to us',
        link: SUPPORT_AND_FAQ_LINK,
        type: LINK_TYPE.WEB_VIEW
    }
]

export const MODEL_CARD_TYPE = {
    RECENTLY_LAUNCHED: 'RECENTLY_LAUNCHED',
    SUBSCRIBED: 'SUBSCRIBED',
    UNSUBSCRIBED: 'UNSUBSCRIBED'
}

export const PILLAR_TYPE = {
    WEALTH_CREATION: 'MintEver',
    WEALTH_PRESERVATION: 'MintBetter',
    INCOME_GENERATION: 'MintNow'
}

export const PILLAR_ENUM = {
    [PILLAR_TYPE.WEALTH_CREATION]: 'mint_ever',
    [PILLAR_TYPE.WEALTH_PRESERVATION]: 'mint_better',
    [PILLAR_TYPE.INCOME_GENERATION]: 'mint_now'
}

export const MODEL_CARD_RETURNS_HEADING = {
    AAR: 'Average Annual Returns',
    CAGR: 'CAGR',
    HEDGE_FACTOR: 'Hedge Factor'
}

export const ABBREVIATED_RETURNS_KEYS = {
    AAR: 'AAR',
    CAGR: 'CAGR'
}

export const DISCOVER_SCREEN_CONTENT = {
    heading: 'Discover',
    subHeading: 'Never a dearth of trading opportunities!',
    stocksHeading: 'Explore stocks',
    stocksSubHeading: 'View all stocks that you can trade with models'
}

export const MODEL_CARD_CONTENT = {
    RISK_PROFILE: 'Risk profile',
    SUBSCRIPTION: 'Subscription',
    SUBSCRIPTION_COUNT: (actionsTaken: number, actionsCount: number) =>
        `${actionsTaken}/${actionsCount} actions completed`,
    RETURNS: 'Returns',
    PERSONALISED_RETURNS: 'Your Returns',
    BACKTESTED_RETURNS: 'Backtested returns',
    NEXT_SIGNAL: 'Next Signal',
    LIVE_SIGNAL: 'Live now',
    HOLDING_PERIOD: 'Held for',
    LIVE: 'Live',
    PENDING_SIGNAL: (numberOfPendingSignals: number) =>
        `${numberOfPendingSignals} ${
            numberOfPendingSignals > 1 ? 'signals' : 'signal'
        }`,
    YEAR: 'year',
    YEARS: 'years'
}

export const PILLAR_PAGE_CONTENT = {
    POINT_TITLE: 'Who this model is great for –',
    MODELS: 'Available models'
}

export const DEVICE_TYPE = {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE',
    APPS: 'APPS'
}

export const USER_PROFILE_ATTRIBUTE = {
    INVESTED_CAPITAL: {
        title: 'Invested capital'
    },
    RETURNS: {
        title: 'Returns'
    },
    CURRENT_VALUE: {
        title: 'Current value'
    },
    BOOKED_PnL: {
        title: 'Booked P&L'
    }
}

export const CHART_CONTENT = {
    DRAW_DOWN: 'Drawdown',
    MAX_DRAW_DOWN: 'Max Drawdown',
    EQUITY_CURVE: 'Equity Curve',
    CUMMULATIVE_RETURNS: 'Returns'
}

export const STREAK_CONTENT = {
    TRADES: '',
    WIN: 'Wins',
    LOSS: 'Losses',
    RETURNS: 'Returns',
    ALL_TRADES: 'Past Signals'
}

export const bottomBarHeight: number = 56

export const SUBSCRIPTION_DETAIL_CONTENT = {
    CAPITAL: {
        title: 'Trading capital',
        description: 'Includes reserve for drawdowns'
    },
    PAPER_CAPITAL: {
        title: 'Virtual capital'
    },
    SUBSCRIPTION: {
        title: 'Min subscription',
        description: 'Action frequency'
    }
}

export const SUBSCRIPTION_CAPITAL_CONTENT = {
    LOSSES: 'This will help offset losses incurred while trading this model.',
    TOTAL_CAPITAL: (amount: string, isEasyTradeFlow) =>
        `Total ${amount} ${
            isEasyTradeFlow ? 'to be held with broker ' : 'of virtual capital '
        }for acting on signals.`,
    NO_RESERVE:
        'You can easily change the capital you wish to deploy while acting on the signals from this model.',
    WITH_RESERVE: percent =>
        `Backtest suggests ${
            percent as string
        }% additional cash to be kept with broker`
}

export const SUBSCRIPTION_WIZARD_STEPS = {
    STEP1: {
        title: 'Overview of',
        description: ''
    },
    STEP2: {
        title: 'Enter the capital for quantity calculator',
        description: '',
        titleWithDerivatives: 'Enter desired lot size'
    },
    STEP3: {
        title: 'Your preferred execution',
        description: ''
    },
    STEP4: {
        title: 'Your broker',
        description: ''
    }
}

export const SUBSCRIPTION_WIZARD_FOOTER_CONTENT = {
    UNSUPPORTED_BROKER: "doesn't support EasyTrade.",
    SELECT_BROKER_NEXT_STEP: 'Select a different broker on the next step.',
    SKIP_BROKER_DETAIL: 'You can give your broker account details later'
}

export const COIN_LEDGER_CONTENT = {
    history: 'History',
    earnedCoin: 'Earned',
    redeemed: 'Redeemed',
    referralEarning: 'Earnings via invites',
    inAppEarning: 'Earnings via in-app actions',
    inviteFriendHeading: 'Invited',
    noRecordsFound: 'No Records'
}

export const executionMethods = [
    {
        title: 'Take actions manually',
        description: 'Punch orders on your preferred broker on your own',
        features: [
            'Turn push notifications on',
            'Act on all signals',
            'Complete actions within 5 min'
        ],
        type: EXECUTION_TYPE.MANUAL
    },
    {
        title: 'EasyTrade',
        description: 'Seamless order punching with select brokers',
        features: [
            'One-click actions',
            '13+ brokers supported'
            // 'Stay logged in throughout the day',
            // 'Automation available soon'
        ],
        type: EXECUTION_TYPE.AUTO
    }
]

export const SUBSCRIPTION_SUCCESS_CONTENT = {
    SUBSCRIPTION_ACTIVE: 'Nicely done! Ready to act?',
    MODEL: 'Model',
    ENABLE_NOTIFICATION: 'ENABLE NOTIFICATIONS',
    PUSH_ALERT_BUTTON_TITLE: 'Push Alerts',
    CTA_TEXT: 'Done',
    SHARE: 'Share & Earn',
    NEXT_SIGNAL_ETA: 'Next Signal',
    SHARE_SIGNAL_TEXT: (shareLink, modelName) =>
        `Hey! I’m now actively trading ${
            modelName as string
        } model with Investmint. I bet you’d like this. Use my referral link to signup and get an accelerated access to the app! ${
            shareLink as string
        }`
}

export const MODEL_SUBSCRIBE_FOOTER_CONTENT = {
    receiveSignals: '⚡️Start receiving signals today',
    easyTrade: 'Easy Trade',
    getSignals: 'Start receiving signals',
    loadingText: 'Loading...',
    paperTrade: 'Paper Trade',
    switchToEasyTrade: 'Switch to EasyTrade',
    alreadySubscribed: 'You’re subscribed to be notified of new signals',
    comingSoonText: '(coming soon)',
    actNowButtonText: 'Use signals for disciplined trading & investing',
    notSubscribedText: 'You are not receiving signals currently',
    alreadyActedText: 'You’re subscribed to be notified of new signals',
    alreadyTrackingText: 'You have tracked this signal for manual trading',
    tradeSignalText: 'Convert to trading position',
    pendingActionTextWithEasyTrade: 'Track or trade Open Signal',
    pendingActionTextWithEasyTradeAndEntered: 'Track or trade Live Signal',
    pendingActionText: 'Track Open Signal',
    pendingActionTextAndEntered: 'Track Live Signal',
    noLiveActionsText: 'No Open Signal for now',
    notifyMeForSignals: 'Notify me about next signals',
    getStartedText: ' Get trading Signals, It’s free',
    startWithLiveSignalsText: 'Start instantly or setup for later',
    startReceivingSignals: "You'll start receiving signals",
    tradeNow: 'Trade now',
    subscribeToNewSignals: 'Start receiving new Signals',
    pendingSignalSubHeading: 'Complete this signal on the next step',
    nonAccessibleHeading: 'Request model access',
    renewModelAccessHeading: 'Renew model access',
    extendModelAccess: 'Extend Model Access',
    nonAccessibleSubHeading: 'Apply now to unlock model signals',
    paymentFailed: 'Try again',
    requestedModelAccessSubHeading:
        'Your model access request has been processed',
    requestedModelAccessHeading: 'Check email and sms for details',
    investMore: 'Invest more',
    yourReturns: 'Your returns'
}

export const MODEL_EXIT_INTENT_SIGNAL = {
    LIVE: {
        heading: 'Are you sure you want to quit?',
        subHeading:
            'We have added this Model to your watchlist where you can track its performance and trade with it in the future',
        primaryCTAText: 'Track & quit',
        secondaryCTAText: 'Continue trading',
        analyticsTargetType: 'signal_dismisal_pop_up'
    },
    NO_SIGNAL: {
        heading: 'Added to your watchlist!',
        subHeading: 'You will now start receiving Signals for this Model'
    }
}

export const REFER_FRIENDS_SECTION = {
    title: 'Refer friends and earn Rewards ♥️',
    subtitle: 'Get them to trade. Rewards coming soon!',
    ctaText: 'Invite friends to Investmint'
}

export const APP_SHARE_TEXT = shareLink => `Hey there!\n
Investmint has made trading very easy for me 🏖️\n
Use my invite link to instantly earn 50 mintcoins and unlock exciting rewards 🎁\n
If you're on an iOS device, just paste the link while signing up 📱\n
Download app now:\n
 ${shareLink as string}`

export const HOME_PAGE_MODELS_CONTENT = {
    getUserWelcomeText: (userName: string) => `Hi ${userName}!`,
    tutorialDescription: 'Get familiar with Investmint',
    welcomeTutorialText: 'Get started with Investmint',
    marketWatchText: 'Marketwatch',
    dotText: '·',
    rideTrendTitle: 'Ride the trend now',
    entityExplainer: 'A quick brief on what we have in store for you',
    refresherVideoText: 'Refresher Videos',
    refresherVideoSubText: 'Easy to follow reels',
    refresherTutorialText: 'Investmint 101 - Back to basics',
    refresherTutorialSubText: 'In case you missed a trick',
    createdByLoveText: ['Crafted with ', '♥️', ' in India 🇮🇳'],
    explainerVideosHeading: ['Watch & Learn ', 'Investmint']
}

export const MINTER = 'Minter'

export const SUBSCRIPTION_STREAK_CONTENT = {
    trackHeading: 'Your Tracking Signals',
    tradeHeading: 'Your Trading Signals',
    pendingSignalsHeading: 'Actionable Signals',
    allModelHeading: 'Your Models',
    nonActedModels: 'Next up',
    noResultsText: 'Get started with one of our models',
    ctaText: '⚡️ Act on Open Signals'
}

export const PRIVACY_POLICY_PAGE_LINK =
    'https://www.investmint.club/privacy-policy'

export const ABOUT_US_PAGE_LINK = 'https://www.investmint.club'

export const PROFILE_FOOTER_CONTENT = {
    READ_MORE: {
        READ: {
            text: 'By using this app, you agree to ',
            type: 'string'
        },
        TERMS_AND_CONDITIONS: {
            text: 'terms & conditions',
            type: 'link',
            url: TERMS_AND_CONDITIONS_PAGE_LINK,
            external: false
        },
        AND: {
            text: 'and',
            type: 'string'
        },
        PRIVACY_POLICY: {
            text: 'privacy policy',
            type: 'link',
            url: PRIVACY_POLICY_PAGE_LINK,
            external: false
        },
        DETAILS: {
            text: '. For more details, visit our ',
            type: 'string'
        },
        ABOUT_US: {
            text: 'website.',
            type: 'link',
            url: ABOUT_US_PAGE_LINK,
            external: true
        }
    },
    APP_UPDATE_AVAILABLE: 'Update to latest version'
}

export const PENDING_ACTIONS_CONTENT = {
    heading: 'Your Signals',
    updatedText: 'Last updated today, ',
    pendingModelHeading: (name: string) => `Signal on ${name}`,
    readOnlyModelHeading: (name: string) => `Update on ${name}`,
    validText: '⚡️ Valid till ',
    actionText: 'signal',
    actionsText: 'signals',
    takeAction: 'View stocks',
    viewActionsText: 'View other signals',
    viewOpenPositionsText: 'Open positions',
    deleteActionContent: {
        heading: 'Review',
        subheading:
            'Once deleted, this signal cannot be brought back. Confirm delete?',
        deleteButton: 'Confirm delete',
        cancelButton: 'Cancel'
    }
}
export const ENABLE_NOTIFICATION_WIZARD = {
    NOTIFICATION_NOT_RECEIVED:
        "You're not receiving critical action notifications",
    ENABLE_NOTIFICATION_TEXT: 'Turn on push notifications'
}

export const PENDING_ACTIONS_SCREEN_CONTENT = {
    NO_ACTION_TEXT: [
        'You have no active signals. ',
        'Start with a model ',
        'to receive personalized signals'
    ],
    GLOSSARY: {
        subHeading:
            'All brokers use different terminology. Here’s a handy guide to make sure you’re placing the right orders when you take action. ',
        content: [
            {
                title: 'Order type',
                description: 'MARKET / LIMIT / SL / SLM'
            },
            {
                title: 'Position type',
                description: 'MIS / CNC / NRML'
            },
            {
                title: 'Validity type',
                description: 'IOC / DAY'
            }
        ]
    }
}

export const lifeCycleStateMapping = {
    INIT_BUY: 'Buy',
    INIT_SELL: 'Sell',
    ENTERED: 'Entered',
    SL_CREATE: 'Place SL',
    SL_UPDATE: 'Update SL',
    SL_HIT: 'SL Hit',
    EXIT_INIT: 'Square off',
    EXIT: 'Closed'
}

export const modelCardTagsContent = {
    RECENTLY_LAUNCHED: 'NEW LAUNCH',
    SUBSCRIBED: 'SUBSCRIBED',
    NON_SUBSCRIBED: 'NOT SUBSCRIBED'
}

export const MODEL_NAMES = {
    QUICK_SHORT: 'Quick Short',
    BACK_UP: 'Back Up',
    BTST: 'BTST',
    NIGHTCRAWLER: 'Nightcrawler',
    HIGH_FIVE: 'High Five'
}

export const ADMIN_PANEL_CONTENT = {
    heading: 'Change environment',
    environmet: 'Environment',
    ctaText: 'DONE',
    subscribeNotificationText: 'Subscribe to Notification test topic',
    unSubscribeNotificationText: 'Unsubscribe to Notification test topic',
    adminLoginText: 'Admin Login'
}

export const FOOTER_CONTENT = {
    company: {
        name: 'Toolbox Technologies Pvt Ltd.',
        email: 'Email: info@investmint.club',
        contact: 'Contact: +91 99123 88981',
        address:
            'C 112, Takshashila Towers\n' +
            'Next to Forum mall, Koramangala 4th block\n' +
            'Bangalore, India 560102'
    },
    hyperlinks: [
        {
            title: 'Company',
            links: [
                {
                    name: 'About us',
                    link: INVESTMINT_WEBSITE_URL
                },
                {
                    name: 'Careers',
                    link: ''
                },
                {
                    name: 'Our team',
                    link: ''
                },
                {
                    name: 'Terms of service',
                    link: 'https://www.investmint.club/terms-conditions'
                },
                {
                    name: 'Privacy policy',
                    link: 'https://www.investmint.club/privacy-policy'
                },
                {
                    name: 'Help & support',
                    link: ''
                }
            ]
        },
        {
            title: 'Fine print',
            links: [
                {
                    name: 'Market information',
                    link: ''
                },
                {
                    name: 'Broker APIs',
                    link: ''
                },
                {
                    name: 'Integration documentation',
                    link: ''
                },
                {
                    name: 'Financial disclaimer',
                    link: ''
                },
                {
                    name: 'Risk profiles',
                    link: ''
                },
                {
                    name: 'SEBI regulatory guidelines',
                    link: ''
                }
            ]
        }
    ]
}

export const COPYRIGHT_SYMBOL = '©'
export const humanReadablePositionType = {
    BUY: 'Buy',
    SELL: 'Sell'
}

export const holdingPosition = {
    BUY: ['Long ', 'since'],
    SELL: ['Short ', 'since']
}

export const initialHoldingPosition = {
    BUY: ['Go ', 'LONG ', 'at'],
    SELL: ['Go ', 'SHORT ', 'at']
}

export const EXECUTION_FLOW_CONTENT = {
    CHANGE_TEXT: 'CHANGE',
    PENDING_AMO_TEXT: '‼️ AMO is already placed',
    triggerPendingText: 'Trigger Pending',
    DISCOVER: 'DISCOVER OTHER MODELS',
    MARK_ACTION_MANUAL: 'Traded manually? Mark as traded',
    CAPITAL_IN_HANDS: 'Capital you have',
    syncOrdersCTAText: 'Sync P&L',
    cancelSLOrderText: 'Cancel SL orders',
    CAPITAL_REQUIRED: 'Trading amount',
    CAPITAL_INVESTED: 'Capital invested',
    noOrderPlaceErrorText: 'No order placed, try again',
    amoOrderRequested: 'AMO',
    changeBrokerText: 'CHANGE BROKER',
    SIGNAL_CAPITAL_CHANGE_TEXT:
        'We’ll save this preference. You can edit anytime',
    COMPLETE_ACTION: ['Trading', 'Signal'],
    ACTION_COMPLETED: 'Signal completed',
    ALL_DONE: 'You’re all done!',
    TRADE_WITH_TEXT: 'Trade with',
    SWIPE_BUTTON: 'Trade Signal with broker', // EASY TRADE + SMALLCASE
    MANUAL_ACTION: 'Traded manually? Mark as traded',
    NOT_SUBSCRIBED_MANUAL_ACTION: 'Traded manually? Mark as traded', // PAPER +  MANUAL ACK,
    DISMISS_ACTION: 'Dismiss this Action',
    entryText: 'Entry',
    exitText: 'Exit',
    updateText: 'Update',
    LOADING: 'Loading...',
    NOTIFICATION_AFTER_ACTION: (modelName: string, showTrack: boolean) =>
        `When you ${
            showTrack ? 'track or ' : ''
        }trade this ${modelName} signal, you'll receive notifications when new signals become available.`,
    futuresLotInfo: '1.5L/lot',
    TRY_AGAIN: 'Try again',
    MODEL_SUBSCRIBE_TEXT: 'NOTIFY ME ABOUT NEW SIGNALS. I’LL START LATER.',
    actionHeader: {
        stockText: 'STOCK',
        livePriceText: '⚡️ PRICE',
        quantityText: 'QTY',
        editText: 'EDIT',
        doneText: 'Done'
    },
    deleteItemContent: {
        heading: 'Are You Sure? ',
        subheading:
            'Removing a stock will deviate your portfolio performance from the model performance.',
        deleteButton: 'Yes, remove',
        cancelButton: 'Cancel'
    },
    lotText: 'Lot',
    lotsText: 'Lots',
    PLACE_SL: 'Place SL'
}

export const EXECUTION_TUTORIAL_CONTENT = {
    ctaText: 'dismiss',
    subHeading:
        'Hope you’ve understood what are models & signals? You can not view this content again.',
    cancelText: 'Keep',
    deleteText: 'Remove'
}

export const DISMISS_MANUAL_CLOSE_ORDER_POPUP = {
    title: 'Are you sure?',
    description:
        'I understand that after this action, I will not be able to place broker orders for this signal.',
    confirm: 'Confirm',
    cancel: 'Cancel'
}

export const PREFILLED_BROKER_INPUT = ['redirectUrl']

export const INVESBROKER_LINK_CONTAINER = {
    heading: 'Make F&O and Intraday trades hassle-free',
    subHeading: 'Now view your trading positions live on the Investmint app',
    ctaText: 'Link broker now'
}

export const SYNC_INVES_BROKER_CONTENT = {
    heading: 'Sync your orders',
    subHeading:
        'Link your broker to track your trades and receive exit signals',
    ctaText: 'Link broker',
    errorText: 'Sync failed',
    loadingCTA: 'Syncing..'
}

export const BROKER_PREFERENCES_CONTENT = {
    headerText: 'Select Broker',
    copyText: 'copy',
    linkBrokerText: 'Link Your broker',
    noBrokerListedText: 'Broker not listed?',
    tradeManuallyText: 'Trade manually',
    moreBrokerComing: '⚡️ MORE BROKERS COMING SOON',
    loginFailedText: 'Login Failed, Try Again',
    brokerLoginSuccessful: 'You have successfully linked your broker',
    configHeading: (brokerName: string) => `${brokerName} Connect`,
    linkBrokerButtonText: 'Save Details',
    loading: 'Loading..',
    updateBrokerButtonText: 'Update Broker Config',
    brokerLoginCta: 'Login to your broker',
    brokerWebLoginLoaderText: 'Fetching details...',
    preferredBroker: 'Your preferred broker',
    comingSoon: 'Coming soon',
    linkBrokerContent: {
        heading: 'Start your trading journey',
        subHeading:
            'Once you connect your preferred broker your everyday trading will become a seamless',
        ctaText: 'Set up your broker'
    },
    brokerSetupTutorial: [
        'Read detailed instructions',
        ' before you proceed with the broker set up here.'
    ]
}

export const TRACK_INVESAPI_BROKER_CONTENT = {
    trackOrderIcon:
        'https://res.cloudinary.com/invesmint/image/upload/v1680297051/assets/readOnlyAccessbroker.png',
    trackOrderHeading: "You're almost there",
    subHeading:
        'Link your broker now to receive exit signal and view this trade in your P&L',
    ctaText: 'Continue',
    ctaSubText: "You don't have to do this everytime"
}

export const NO_BROKER_LISTED_CONTENT = {
    heading: 'Why trade manually?',
    subHeading: 'Find this option below swipe to trade to',
    salientPoints: [
        'Track this position in your P&L',
        'Receive alert to exit '
    ],
    ctaText: 'Done'
}

export const UPDATE_CAPITAL_CONTENT = {
    MAX_CAPITAL: 'Enter Trading Capital',
    CTA_TEXT: 'Save',
    UPDATE_LOT: 'Update Lot allocation',
    LOADING: 'Loading'
}

export const UNSUBSCRIBE_CONTENT = {
    HEADING: 'Stop signals',
    DESCRIPTION:
        'Once you stop signals, you will no longer receive any new signals.',
    CTA_TEXT: 'Stop signals',
    UNSUBSCRIBE_MODEL: 'Stop signals',
    SHARE: 'Share with friends',
    NEED_HELP: 'Need help?',
    DISCLAIMER_TEXT: `You've already acted on live signals. You can "Stop signals" after the current position gets exited.`,
    SWITCH_TO_PAPER_TRADE: 'Switch to paper trade',
    SUBSCRIPTION_VALID_TILL:
        'You have paid for access to this model. It is valid till ',
    FREE_SUBSCRIPTION_VALID_TILL:
        'Your 14-day no questions asked 100% refund period is valid till '
}

export const OPEN_POSITIONS_CONTENT = {
    tradeHeading: 'Trading Positions',
    trackHeading: 'Tracking Positions',
    startJourneyText: 'Start your trading journey',
    investedText: 'Invested',
    view: 'View',
    pnl: 'PnL',
    qty: 'Qty',
    ltpText: 'LTP',
    entryText: 'Entry',
    exitText: 'Exit',
    avg: 'Avg',
    holdingPeriod: 'Held for',
    tradeCtaText: 'Go to dashboard',
    noOpenPositionsText: 'No Open Positions',
    noPositions: 'No trades executed yet',
    perLotText: '/lot',
    learnMore: 'Learn More',
    positionsText: 'Intraday and F&O',
    viewModelDetails: 'View model',
    trackingNudgeText:
        'The prices here are how Investmint tracks signal prices. In certain cases, you might not see any prices. ',
    awaitingEntry: 'Awaiting entry',
    holdingAndPositions: 'Holding & positions',
    watchlistText: 'Your watchlist',
    brokerOrderText: 'Order',
    exitedPositionText: 'Exited Trade',
    manualOrderText: 'Manual Order',
    amoPendingOrder: 'AMO Pending',
    amoOrder: 'AMO',
    pnlText: 'P&L',
    orderText: 'Order',
    noPnlHeading: 'No trades to show here',
    noPnlSubHeading: 'Make a trade to view your P&L',
    amoRefetchAgain: 'Refetch Again',
    amoRefetchError: 'No update from broker yet',
    amoRefetchSuccess: 'Positions Updated',
    fetchingText: 'Fetching...',
    yourHoldingPositions: 'Your holding positions',
    yourPositions: 'Your positions',
    nothingFoundIconUrl:
        'https://res.cloudinary.com/invesmint/image/upload/v1678770053/assets/nothingFound_jgwich.png'
}

export const AMO_NUDGE_CONTENT = {
    fetchingText: 'Fetching...',
    amoRefetchError: 'Copy about error',
    amoNudgeText: 'Awaiting order data from your broker',
    amoRefetchText: 'Fetch Again'
}
export const SIGNAL_TIMELINE_CONTENT = {
    LIFECYCLE: 'LIFECYCLE',
    DATE_TIME: 'DATE & TIME',
    SL_CREATE_LABEL: 'Stop-loss placed at',
    SL_UPDATE_LABEL: 'Stop-loss adjusted to',
    PRICE: 'Price',
    UPDATE: 'Update',
    TRADE_INIT: 'Trade initiated',
    SL_HIT: 'Stop-loss got hit',
    INVALIDATED: 'The trade is invalid now',
    EXIT_INIT: 'Exit initiated',
    INIT_DERIVATIVES_LABEL: 'Trigger Price',
    EXITED_SIGNAL_MESSAGE: 'This model signal is no longer valid.',
    EXIT_SIGNAL_CTA: 'Exit Trade',
    DELETE_SIGNAL_CONTENT: {
        HEADING: 'Review',
        SUB_HEADING:
            "Remember to take appropriate buy/sell trade with your broker if you're still holding this instrument",
        DELETE_BUTTON: 'Confirm delete',
        CANCEL_BUTTON: 'Cancel'
    },
    AMO_HEADING:
        'This stock order is still pending. This entry will update when markets open next.',
    AMO_DESCRIPTION:
        'If you placed this by mistake, you may cancel this order directly with your broker'
}

export const ERROR_UI_CONTENT = {
    SOMETHING_WENT_WRONG: 'Something went wrong!',
    CTA_TEXT: 'Back to home',
    INSTALL_NEW_VERSION: 'Install new version'
}

export const fyiSignalLifecycleState = [
    LifeCycleState.ENTERED,
    LifeCycleState.SL_HIT,
    LifeCycleState.EXIT,
    LifeCycleState.INVALIDATED
]

export const OPEN_EMAIL_POST_MESSAGE = 'open-email'

export const CUTS_SECTIONS_CONTENT = {
    CTA_TEXT: (count: number) => `View ${count === 1 ? 'Model' : 'Models'}`
}

export const ASSUMED_CAPITAL_TO_TRADE = 100000
export const BAR_WIDTH: number = 10
export const DISCOVER_ALL_MODELS = 'DISCOVER MODELS'
export const FIREBASE_DOMAIN_URI_PREFIX = 'https://investmint.page.link'
export const ONE_LINK_URL = `${FIREBASE_DOMAIN_URI_PREFIX}/d6o5`
export const STORY_THUMBNAIL =
    'https://res.cloudinary.com/invesmint/image/upload/v1664438507/playstore-icon_hsewa0.png'

export const ALL_TRADES_CONTENT = {
    ALL_TRADES_TITLE: 'All Trades',
    NO_TRADES_FOUND: 'No trades found',
    CLOSED_SIGNAL: 'CLOSED SIGNALS',
    SIGNAL_DATE: 'SIGNAL DATE'
}

export const MAINTENANCE_ILLUSTRATION =
    'https://res.cloudinary.com/invesmint/image/upload/v1664787837/maintenance-illustration_mppkxy.png'

export const VIDEO_STREAM_FORMAT = {
    MPD: 'mpd',
    M3U8: 'm3u8'
}

export const LINK_TO_LEARN_ABOUT_PRICE =
    'https://investmintclub.freshdesk.com/support/solutions/articles/89000004777-why-do-i-not-see-average-price-in-tracking-positions-section-for-some-instruments-'

export const NON_TRADEABLE_MODEL_SWIPE_POPUP =
    'We do not support broker order execution for this model'

export const TICKER_WEBSOCKET_URL = 'wss://aventador.investmint.club/websocket'

export const INVESTMENT_AMOUNT_CONTENT = {
    totalInvestment: 'Invested',
    currentValue: 'Current',
    trackedAmount: 'Tracked Amount',
    pnl: 'P&L',
    investedText: 'Invested',
    currentText: 'Current',
    myHolding: 'Holdings'
}

export const HIDE_CADENCE_MODELS = ['nifty_rider']
export const PAID_MODEL_KEYS = ['nifty_rider']
export const INDEX_INSTRUMENTS = {
    NIFTY: {
        indexToken: 256265,
        name: 'Nifty 50',
        exchange: 'NSE',
        tradingSymbol: 'NIFTY 50'
    },
    BNF: {
        indexToken: 260105,
        name: 'Bank Nifty',
        exchange: 'NSE',
        tradingSymbol: 'NIFTY BANK'
    }
}

export const WASABI_ENDPOINT =
    'https://s3.ap-southeast-1.wasabisys.com/codepush.investmint.club/'

export const DEFAULT_STOCKS_FILTER_PILLS = {
    DEFAULT: {
        title: '1d',
        id: 'day'
    },
    WEEKLY: {
        title: '7d',
        id: 'week'
    },
    BI_WEEKLY: {
        title: '2w',
        id: 'bi-weekly'
    },
    MONTHLY: {
        title: '1m',
        id: 'month'
    },
    QUARTERLY: {
        title: '3m',
        id: 'quarter'
    },
    HALF_YEARLY: {
        title: '6m',
        id: 'half-yearly'
    },
    YEARLY: {
        title: '1y',
        id: 'year'
    }
}

export const USERS_PHONE_BLOCKED_FROM_PAID_MODEL = [
    '919442594425',
    '917831838003'
]

export const NOTIFICATION_CENTER_CONTENT = {
    title: 'Notification Center',
    noNotifications: 'No new notifications!'
}

export const STOCK_CARD_CONTENT = {
    HOLDING: 'Holding',
    VIEW_DETAILS: 'VIEW STOCK DETAILS',
    LAST: 'Last',
    CHARTS_LOADING_ERROR: 'Error while loading charts...',
    CHARTS_LOADING: 'Loading...',
    LIVE: 'Live'
}

export const STOCK_DETAILS_CONTENT = {
    MODEL: 'Model: ',
    DISCOVER_SCREEN: 'Discover Models',
    OTHERS_STOCKS: (count: number, modelName: string) =>
        `${count} other ${
            count > 1 ? 'stocks' : 'stock'
        } are a part of ${modelName}`,
    CHART_LEGENDS_WITH_MODELS: [
        {
            title: 'Model price',
            background: colors.BRAND.BLUE,
            backgroundOuter: colors.BRAND_LIGHT.BLUE
        },
        {
            title: 'High',
            background: colors.BRAND.GREEN,
            backgroundOuter: colors.BRAND_LIGHT.GREEN
        },
        {
            title: 'Low',
            background: colors.BRAND.RED,
            backgroundOuter: colors.BRAND_LIGHT.RED
        }
    ],
    CHART_LEGENDS_WITHOUT_MODELS: [
        {
            title: 'High',
            background: colors.BRAND.GREEN,
            backgroundOuter: colors.BRAND_LIGHT.GREEN
        },
        {
            title: 'Low',
            background: colors.BRAND.RED,
            backgroundOuter: colors.BRAND_LIGHT.RED
        }
    ],
    MODEL_PAGE_TABS: {
        MODELS: 'Models',
        LEARN: 'Learn',
        COMPANY: 'Company'
    },
    NEWS_SHARE_INTENT_TEXT: (link: string, url: string) =>
        `Hey, I found this interesting news piece on the Investmint app. Check it out — ${url} Btw, you should check out this Investmint app. Use this link to download ${link}`,
    STOCK_DETAILS_TAB_WITHOUT_MODELS: ['Learn', 'Company'],
    STOCK_DETAILS_TAB_WITH_MODELS: ['Models', 'Learn', 'Company'],
    ABOUT_STOCK_SECTION: {
        TITLE: 'Performance',
        FUNDAMENTALS: 'Fundamentals ',
        TIME_PERFORMANCE: 'Time wise performance ',
        MODEL_PERFORMANCE: 'Models wise performance',
        ABOUT: 'About',
        NAME_BY_DETAIL: {
            fullName: 'Full Name',
            parentCompany: 'Parent Company',
            headquarters: 'Headquarters',
            ceo: 'CEO',
            managingDirector: 'Managing Director',
            foundedYear: 'Founded Year',
            websiteUrl: 'Website'
        },
        RANGE_SECTION: {
            DAY: {
                LOW: `Today's Low`,
                HIGH: 'Today’s High'
            },
            QUARTER: {
                LOW: `3 month Low`,
                HIGH: '3 month  High'
            },
            YEAR: {
                LOW: `52 weeks Low`,
                HIGH: '52 weeks High'
            }
        },
        RANGE_TYPE: {
            DAY: 'DAY',
            QUARTER: 'QUARTER',
            YEAR: 'YEAR'
        },
        INSIGHT_TYPE: {
            INDUSTRY: 'Industry',
            MARKET_CAP: 'Size',
            SECTOR: 'Sector'
        },
        MARKET_CAP: {
            LARGE: 'Largecap',
            MID: 'Midcap',
            SMALL: 'Smallcap'
        },
        STOCK_PRICE_DATA_POINTS: {
            OPEN: 'Open',
            PREV_CLOSE: 'Prev. Close',
            VOLUME: 'Volume',
            LC: 'Lower Circuit',
            UC: 'Upper Circuit'
        }
    },
    NEWS_SECTION: {
        SELECTION_PILL: {
            NEWS: 'All News',
            ANALYSIS: 'Analysis'
        },
        NEWS_FILTERS_TYPES: {
            TRENDING: {
                title: 'Trending'
            },
            NEW: {
                title: 'New'
            },
            Top: {
                title: 'Top'
            }
        },
        AGO_TEXT: 'ago',
        NEWS_LAYOUT: {
            LIST: 'LIST',
            CARD: 'CARD'
        }
    },
    MODEL_COUNT_TITLE: (symbol: string, modelsCount: number) =>
        `${modelsCount > 1 ? 'Models' : 'Model'} that this stock is a part of`,
    CTA_TEXT: (symbol: string, modelName: string) => `Trade with ${modelName}`,
    LOADING_TEXT: 'Loading...',
    TUTORIAL: {
        QUESTION_HEADING: 'What are models?',
        QUESTION_SUBHEADING: 'Read mode about models',
        DISMISS: 'Dismiss'
    },
    DISMISS_MODEL_TUTORIAL: {
        HEADING: '',
        SUB_HEADING:
            'Hope you’ve understood what are models? You can view this content from the homepage again.',
        DELETE_BUTTON: 'Remove',
        CANCEL_BUTTON: 'Keep'
    }
}

export const STOCKS_ANALYSIS_CONTENT = {
    MODELS_TITLE: 'Investmint models mentioned',
    AGO_TEXT: 'ago'
}

export const STOCK_INTERVAL_FILTER_PILL = [
    {filterId: 'd1', filterText: '1d'},
    {filterId: 'd7', filterText: '7d'},
    {filterId: 'd14', filterText: '2w'},
    {filterId: 'd30', filterText: '1m'},
    {filterId: 'd90', filterText: '3m'},
    {filterId: 'd180', filterText: '6m'},
    {filterId: 'y1', filterText: '1y'}
]

export const MODELS_ICON_IMAGE =
    'https://res.cloudinary.com/invesmint/image/upload/v1677537272/assets/whatAreModels_ozd918.png'

export const STOCK_FILTER_TYPES = {
    FILTER: 'time_window',
    SORT: 'trend'
}

export const VIEW_STOCK_DETAILS = 'View Stock details'

export const APP_REVIEW_CONTENT = {
    TITLE: 'Enjoying Investmint?',
    DESCRIPTION: 'Please share your experience rating',
    SUBMIT_BUTTON: 'Submit',
    NOT_NOW_BUTTON: 'Not now',
    CANCEL_BUTTON: 'Cancel'
}
export const ONBOARDING_TUTORIAL_TYPES = {
    LIVE_MODEL: 'LIVE_MODEL',
    MODEL: 'MODEL',
    SIGNAL: 'SIGNAL',
    COMMUNITY: 'COMMUNITY'
}

export const TUTORIAL_CONTENT = {
    modelText: ' Model',
    dismissOnboarding: {
        heading: '',
        subHeading:
            'Hope you’ve understood what are models & signals? You can not view this content again.',
        deleteText: 'Remove',
        cancelText: 'Keep'
    },
    supportEmail: 'support@investmint.club',
    dismissText: 'don’t show again',
    faqText: 'discuss & learn',
    loadingText: 'loading...',
    communityTilePrimaryCTAText: 'joinmintclub'
}

export const TRUST_ELEMENT_TYPE = {
    USER: 'USER',
    FEE: 'FEE',
    SAFE: 'SAFE'
}

export const BROKERS_IMAGES = {
    kite: 'https://res.cloudinary.com/invesmint/image/upload/v1676729368/assets/kite_ncplkt.png',
    dhan: 'https://res.cloudinary.com/invesmint/image/upload/v1676728747/assets/dhan_vaguec.png'
}

export const BROKER_SUPPORT_TRUST_CONTENT = {
    title: 'Your money stays with your broker',
    description: 'Place orders with your broker of choice',
    flowTrustText: 'flow powered by',
    moreBrokerText: '+11 more',
    brokers: [
        'https://res.cloudinary.com/invesmint/image/upload/v1674234007/assets/groww.png',
        'https://res.cloudinary.com/invesmint/image/upload/v1674234007/assets/zerodha.png',
        'https://res.cloudinary.com/invesmint/image/upload/v1674234007/assets/angelOne.png',
        'https://res.cloudinary.com/invesmint/image/upload/v1674234007/assets/upstox.png',
        'https://res.cloudinary.com/invesmint/image/upload/v1674234007/assets/icici.png'
    ],
    trustElements: {
        USERS_COUNT: {
            type: TRUST_ELEMENT_TYPE.USER,
            title: 'users',
            titleBold: '100,000+'
        },
        FREE: {
            type: TRUST_ELEMENT_TYPE.FEE,
            title: 'fees',
            titleBold: '0'
        },
        SAFE: {
            type: TRUST_ELEMENT_TYPE.SAFE,
            title: 'safe',
            titleBold: '100%'
        }
    }
}

export const COMMUNITY_CONTENT = {
    title: 'Join mint club',
    description: 'Learn, discuss & connect with over 4,000 active traders!',
    communityUrl:
        'https://res.cloudinary.com/invesmint/image/upload/v1677556864/assets/comunity_aqqkii.png',
    salientPoints: [
        '3k+ active members',
        'learn about markets',
        'ask question or discuss',
        'members-only community'
    ],
    ctaText: 'Join Now',
    telegramLogo:
        'https://res.cloudinary.com/invesmint/image/upload/v1674246702/assets/telegram.png'
}

export const MODELS_COMPARISON_TABS = {
    POPULAR: 'popular',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    BIWEEKLY: 'bi-weekly',
    MONTHLY: 'monthly'
}

export const MODEL_COMPARISON_TABLE_CONTENT = {
    heading: 'Explore models',
    subHeading: 'Trade with effective, data-backed strategies',
    tabs: [
        MODELS_COMPARISON_TABS.POPULAR,
        MODELS_COMPARISON_TABS.DAILY,
        MODELS_COMPARISON_TABS.WEEKLY,
        MODELS_COMPARISON_TABS.BIWEEKLY,
        MODELS_COMPARISON_TABS.MONTHLY
    ],
    layout: {
        GRID: 'grid',
        LIST: 'list'
    },
    modelTutorialHeading: 'Know more about models',
    modelTutorialSubHeading: 'Get up to speed with trading models in 2 minutes.'
}

export const MY_INVESTMENT_SECTION = {
    heading: 'Your trading returns',
    subHeading: 'summary of your holdings and positions.',
    modelText: 'Model',
    pnlHeading: 'P&L',
    positionsText: 'Intraday and F&O',
    yourPositionsText: 'Positions',
    yourPnl: 'Your P&L',
    overviewSubHeading: 'Track the performance of your trades in one place',
    viewDetailsText: 'View details'
}

export const MY_WATCHLIST_SECTION = {
    heading: 'Your watchlist',
    subHeading: 'All models you are trading or tracking in one place',
    convertToTrading: 'Convert to trading position ',
    pnlHeading: 'P&L',
    positionsText: 'Your Positions',
    exitOnText: 'Exit on',
    nextSignalText: 'Next Signal:',
    hotTimeToEnter: ['🔥', 'Hot time to enter'],
    ctaTextMapping: {
        ADD_MORE: 'Add More',
        TRADE: 'Trade now',
        VIEW_HISTORY: 'View History'
    },
    userActionStateText: {
        TRADING: 'Traded',
        TRACKING: 'Tracking',
        AMO_PENDING: 'Pending AMO'
    },
    noWatchListHeading: 'No models in your watchlist',
    noWatchListSubHeading:
        'Explore trading models to start your systematic trading journey.',
    nothingFoundIconUrl:
        'https://res.cloudinary.com/invesmint/image/upload/v1678770053/assets/nothingFound_jgwich.png'
}

export const MODEL_COMPARISON_ASSETS = {
    MODEL: 'Long India',
    SIP: 'SIP',
    MF: 'MF'
}

export const MODEL_PROMOTION_CONTENT = {
    boosterText: 'Try a Signal',
    primaryCTAText: 'Start with ₹200',
    sampleText: 'monthly investment of 10k from 2018 would currently be ',
    secondaryCTAText: 'Notify me of next signals',
    loadingText: 'Loading...',
    modelText: 'Model',
    learnMoreText: 'Learn more',
    alreadyNotifiedText: 'You are receiving signals',
    comparisonAssets: [
        {
            type: MODEL_COMPARISON_ASSETS.MODEL,
            title: 'Long India',
            sampleWidth: 85,
            sampleAmount: '11 Lakhs'
        },
        {
            type: MODEL_COMPARISON_ASSETS.SIP,
            title: 'Nifty 50 ETFs',
            sampleWidth: 85,
            sampleAmount: '8 Lakhs'
        },
        {
            type: MODEL_COMPARISON_ASSETS.MF,
            title: 'MFs',
            sampleWidth: 50,
            sampleAmount: '5 Lakhs'
        }
    ],
    modelPitchText: 'not a rocket science, just a data-backed way to invest ',
    longIndiaIcon:
        'https://res.cloudinary.com/invesmint/image/upload/v1674131383/assets/IndiaFlag_lrfnun.png'
}

export const INTRADAY_DERIVATIVES_MODELS_KEYS = [
    ModelEnums.QUICK_SHORT,
    ModelEnums.NIFTY_RIDER,
    ModelEnums.BACK_UP,
    ModelEnums.NIGHT_CRAWLER
]

export const SLIDING_TIME_FRAME = ['10min', '5min', '3min', '1min']
export const REQUIRED_CHART_DATA_POINTS = 4

export const REFERRAL_FOOTER_TYPE = {
    FAQ: 'FAQ',
    TNC: 'TNC',
    HELP: 'HELP'
}

export const REFERRAL_CHANNEL = {
    WHATSAPP: 'WhatsApp'
}

export const REFERRAL_SCREEN_CONTENT = {
    heading: 'Share your trading journey',
    subHeading: 'Invite friends and family to earn rewards ✨',
    headingAfterReferral: 'Earn 500 mintcoins',
    subHeadingAfterReferral: 'On your friend’s first trade ✨',
    howItWorksText: 'How it works ?',
    viewRewardHistory: 'View earnings',
    inviteViaWhatsApp: 'Invite via Whatsapp',
    inviteNow: 'Invite Now',
    seeHowItWorks: 'See how it works',
    referralFooterLinks: [
        {
            type: REFERRAL_FOOTER_TYPE.FAQ,
            title: 'Frequently Ask Questions',
            link: SUPPORT_AND_FAQ_LINK
        },
        {
            type: REFERRAL_FOOTER_TYPE.TNC,
            title: 'Terms & Conditions',
            link: TERMS_AND_CONDITIONS_PAGE_LINK
        },
        {
            type: REFERRAL_FOOTER_TYPE.HELP,
            title: 'Help & Support',
            link: SUPPORT_EMAIL
        }
    ],
    tutorialContent: [
        {
            heading: 'Invite a friend',
            description:
                'Click on the invite button to share your unique invite link with friends and family',
            iconUrl:
                'https://res.cloudinary.com/invesmint/image/upload/v1676637764/assets/invite_referral_mcmtqk.png'
        },
        {
            heading: 'Earn 500 mintcoins on their first trade',
            description:
                '500 mintcoins will be credited to your wallet as soon as your friend makes their first trade',
            showExample: true,
            iconUrl:
                'https://res.cloudinary.com/invesmint/image/upload/v1676637764/assets/coin_referral_g6mrxy.png',
            exampleHeading: 'Ask your friend to',
            examplePoints: [
                "Sign up on the Investmint app with your unique invite link (if they use an iOS device, they'll have to paste the link during sign up)",
                'They explore different models and make their first trade by acting on the signal from that model',
                'You earn 500 mintcoins once they complete the trade using the app'
            ]
        },
        {
            heading: 'Unlock rewards',
            description: 'Redeem your mintcoins to earn amazing rewards',
            iconUrl:
                'https://res.cloudinary.com/invesmint/image/upload/v1676637764/assets/unlock_referral_noakwh.png'
        }
    ],
    headerIcon:
        'https://res.cloudinary.com/invesmint/image/upload/v1676099818/assets/referralHeader_buxbjz.png'
}

export const MINT_COIN_CONTENT = {
    yourBalanceHeading: 'Your mintcoins balance',
    currentBalanceHeading: 'Current mintcoins balance',
    understandMintCoin: 'Know more about mintcoins',
    seeMore: 'See what are mintcoins',
    redeemNow: 'Redeem now',
    introducingMintCoins: 'Introducing  mintcoins',
    mintCoinsExplainer: [
        'Our way of rewarding your trust',
        'Earn with every key action',
        'Redeem rewards curated for you'
    ],
    dismissMintCoinExplainer: {
        subHeading:
            "Are you sure you want to dismiss this? You won't be able to undo this.",
        cancelText: 'Keep',
        deleteText: 'Remove'
    },
    walletAndCoinIcon:
        'https://res.cloudinary.com/invesmint/image/upload/v1676386236/assets/walletAndCoin_smanbz.png',
    lifetimeEarningText: 'Lifetime earnings',
    earningHistory: 'Earnings history',
    understandMintCoinHeading: ['Know more about', 'mintcoins'],
    coinStackIcon:
        'https://res.cloudinary.com/invesmint/image/upload/v1676099817/assets/coinstack_lgpknq.png'
}

export const USER_REFERRAL_SECTION = {
    referredUsersText: 'Your invited users',
    tradedText: 'Traded',
    yetToTradeText: 'Yet to trade',
    signupText: 'Signup',
    firstTradeText: 'First trade',
    secondTradeDoneText: 'Second trade',
    thirdTradeDoneText: 'Third trade',
    threeTimesTradedUsersText:
        'Made 3 trades for unique signals of the same model',
    coinEarnedText: 'mintcoins earned',
    minimizedCoinText: 'mintcoins',
    signedUpTime: 'Signed up on',
    bonusText: 'bonus'
}

export const COMING_SOON_REWARDS_CONTENT = {
    heading: ['Rewards unlike you', 'have ever seen'],
    comingSoon: 'Coming soon',
    earnMintCoin: 'Earn mintcoins now.',
    thankYou: 'Thank us later.',
    earnNowCTA: 'Earn Now',
    mintCoinToBeEarned: '150',
    tradeAndGetAwards: ['Complete your', ' first trade', ' and get rewarded'],
    viewRewards: 'View rewards',
    starIcon:
        'https://res.cloudinary.com/invesmint/image/upload/v1676658553/assets/Star_othhpe.png'
}

export const EARNING_PAGE_CONTENT = {
    startEarning: 'Start earning',
    earnMintCoin: 'Earn mintcoins',
    startEarningSteps: ['Get more mintcoins with', '3 easy steps'],
    quizDetailedStep: [
        'Tell us about yourself with this quick quiz and earn 250 mintcoins'
    ],
    redeemEarning: 'Redeem mintcoins',
    quizAnswer: 'Answer now',
    lovingTheApp: 'Invite, earn, repeat',
    inviteNow: 'Invite now',
    knowMore: 'Know more',
    tradeMore: 'Trade more. Earn more.',
    tradeDetailedStep: 'Trade with a model and earn 200 coins',
    tradeMoreDescription:
        'Every trade counts. Trade with models to earn mintcoins',
    exploreModels: 'Discover models',
    inviteFriendsDescription:
        "Earn 500 mintcoins on your friend's first trade!",
    rewardsText: ['Rewards', 'Coming Soon'],
    rewardsDescription:
        'Curating rewards just for you! We promise it’ll be worth it',
    earnMintCoinIcon:
        'https://res.cloudinary.com/invesmint/image/upload/v1676391226/assets/earnMintCoin_ygmj1l.png',
    referAppIcon:
        'https://res.cloudinary.com/invesmint/image/upload/v1676437340/assets/referApp_zdsou7.png',
    tradeMoreIcon:
        'https://res.cloudinary.com/invesmint/image/upload/v1676445078/assets/tradeMore_inramc.png',
    rewardsIcon:
        'https://res.cloudinary.com/invesmint/image/upload/v1676446857/assets/rewards_g9bjkz.png'
}

export const USER_MINTCOIN_JOURNEY_STEPS = {
    SIGN_UP: {
        title: 'Sign up',
        type: MintCoinPurpose.USER_SIGNUP,
        state: 'signup_completed'
    },
    QUIZ: {
        title: 'Complete Quiz',
        type: MintCoinPurpose.COMPLETE_PROFILE,
        state: 'quiz_completed'
    },
    TRADE: {
        title: 'Perform 1st trade',
        type: MintCoinPurpose.USER_FIRST_TIME_INITIATE_POSITION,
        state: 'trade_completed'
    }
}

export const SUCCESSFUL_ORDER_CONTENT = {
    successHeading: 'You earned',
    amoSuccessCoins: "You'll earn",
    mintcoinsText: 'mintcoins',
    successCTAText: 'Go back to trading positions',
    viewRewardsText: 'Know more about rewards',
    successfulTradeText: 'Trade Successful!',
    amoOrderSuccessfulText: 'AMO Request Received'
}

export const NOTICE_BOARD_CONTENT = {
    iconUrl:
        'https://res.cloudinary.com/invesmint/image/upload/v1677514160/assets/noticeBoard_efnf9j.png',
    heading: 'Notice board',
    linkText: 'Link'
}

export const DO_MORE_SECTION_CONTENT = {
    heading: 'Do more',
    subHeading: 'Explore new opportunities on Investmint'
}

export const SYSTEMATIC_INVESTING_CONTENT = {
    heading: ['How to', 'trade systematically?'],
    salientPoints: [
        {
            title: 'Select a model that suits you',
            icon: '1️⃣'
        },
        {
            title: 'Act on timely signals you receive',
            icon: '2️⃣'
        },
        {
            title: 'Trade with your broker of choice',
            icon: '3️⃣'
        },
        {
            title: 'Repeat and get rewarded',
            icon: '4️⃣'
        }
    ],
    iconUrl:
        'https://res.cloudinary.com/invesmint/image/upload/v1679092872/assets/systematicTrader_dmqsbk.png'
}

export const DASHBOARD_TABS = {
    SIGNALS: 'Signals',
    PNL: 'P&L'
}

export const DASHBOARD_CONTENT = {
    heading: 'Dashboard',
    sectionSubHeading: 'Your signals and P&L at your fingertips',
    tabs: [DASHBOARD_TABS.SIGNALS, DASHBOARD_TABS.PNL],
    deepLinkTabs: {
        pnl: 'pnl'
    }
}

export const TESTIMONIAL_CONTENT = {
    heading: 'Testimonials',
    addTestimonial: {
        heading: 'Share your story',
        subHeading: 'Tell what you like about Investmint to 100,000+ users!',
        ctaText: 'Write testimonial',
        iconUrl:
            'https://res.cloudinary.com/invesmint/image/upload/v1678192840/assets/testimonial_kjghv6.png'
    },
    iconUrl:
        'https://res.cloudinary.com/invesmint/image/upload/v1677598248/assets/testimonial_lut4y4.png'
}

export const ACTIONABLE_SIGNALS_CONTENT = {
    signalsText: 'Signals',
    signalText: 'Signal',
    ctaText: 'Act now',
    actionableSignals: 'Your actionable signals',
    noActionableText: 'You have no actionables',
    chooseModelText: 'Select a model and start receiving signals',
    actionableSubText: 'Signals that you need to act on now',
    liveText: 'Live now',
    moreText: 'more',
    actionableText: 'Your actionables',
    actionableSectionSubHeading: 'Signals that you need to act on now',
    startJourneyText: 'Explore Models',
    modelCountText: 'models',
    nothingFoundIconUrl:
        'https://res.cloudinary.com/invesmint/image/upload/v1678770053/assets/nothingFound_jgwich.png',
    noActionableSignalsIconUrl:
        'https://res.cloudinary.com/invesmint/image/upload/v1678770054/assets/noActionable_gpilyw.png'
}

export const SIGNAL_BOX_CONTENT = {
    actNowCTA: 'Act Now',
    partialTrader: 'Partially traded',
    entryText: 'Entry',
    exitText: 'Exit',
    updateText: 'Update',
    slCancelText: 'SL Cancel',
    leftText: 'left',
    deleteFromListText: 'Delete from list'
}

export const exitLifeCycles = [
    LifeCycleState.EXIT_INIT,
    LifeCycleState.EXIT,
    LifeCycleState.SL_HIT,
    LifeCycleState.INVALIDATED
]

export const ONBOARDING_CONTENT = {
    skipButton: 'Skip',
    nextText: 'Next',
    getStartedText: 'Get Started'
}

export const SIGNUP_SUCCESS_CONTENT = {
    heading: 'Yayy!  You’re in the',
    description: ['Invest', 'mint', ' universe'],
    getStartedText: 'Get Started'
}

export const CORE_CONCEPTS_EXPLAINER_CONTENT = {
    getStartedText: 'Get Started',
    skipToHome: 'Skip, Take me to home',
    nextText: 'Next',
    previousText: 'Previous'
}

export const CANCELLABLE_ORDER_CONTENT = {
    heading: 'Cancel your stop loss orders',
    ctaText: 'Cancel with broker',
    cancelSLText: 'SL cancel',
    ordersOverview: 'Order summary',
    actionHeader: {
        stockText: 'STOCK',
        livePriceText: '⚡️ PRICE',
        quantityText: 'QTY'
    },
    subHeading: 'Not canceling your SL orders can lead to losses'
}

export const STORY_BOTTOM_BAR_CONTENT = {
    nextText: 'Next',
    previousText: 'Previous'
}

export const LINK_BROKER_CONTENT = {
    heading: 'Link your broker',
    invesBrokerHeadingText: 'Intraday Broker',
    smallcaseBrokerHeadingText: 'Delivery Broker',
    invesBrokerSubHeadingText: 'Link your Intraday Broker',
    smallcaseBrokerSubHeadingText: 'Link your Delivery Broker',
    changeBrokerText: 'Select',
    loadingText: 'Loading...',
    linkBrokerNowText: 'Link your broker',
    noBrokerFound: 'No linked broker yet',
    disconnectingText: 'Updating...'
}

export const LANGUAGE_DISPLAY_NAME = {
    [ONBOARDING_LANGUAGES.ENGLISH]: 'En',
    [ONBOARDING_LANGUAGES.HINDI]: ' हिं '
}

export const EXPLORE_MODELS_CONTENT = {
    heading: 'Select a model that suits you',
    modelCountText: '11 models to trade or invest',
    ctaText: 'Explore models'
}

export const SEE_HOW_IT_WORKS = {
    iconUrl:
        'https://res.cloudinary.com/invesmint/image/upload/v1679081020/assets/seeHowItWorks_zde6jx.png',
    heading: ['Confused? Here’s', 'a quick refresher'],
    ctaText: 'See how it works'
}

export const BROKER_MISMATCH_CONTENT = {
    heading: 'Broker Changed',
    description: oldBrokerName =>
        `Your initial order was placed with ${
            oldBrokerName as string
        }. Please change back to ${
            oldBrokerName as string
        } to act on this signal.`,
    ctaText: oldBrokerName => `Change to ${oldBrokerName as string}`
}

export const CUSTOM_SIGNAL_EXIT_STATE = 'CUSTOM_EXIT'
export const EXIT_TRADE_CONTENT = {
    heading: 'Are you sure you want to exit this trade now?',
    subHeading: ' ⚠️ Note that you are exiting this trade prematurely',
    cancelCTAText: 'Cancel',
    reviewAndExitCTAText: 'Review & exit',
    exitCTAText: 'Exit trade'
}
