export type MintCoinAwardList = Record<
    MintCoinPurpose,
    Record<string, string | number>
>

export enum TransactionType {
    DEBIT = 'DEBIT',
    CREDIT = 'CREDIT'
}

export interface UserMintCoinBalance {
    balance: number
    totalCredited: number
    totalCredit?: number
}

export interface InvitedUser {
    uuid: string
    name: string
    phone: number
}

export enum MintCoinPurpose {
    /* Debit */
    MEMBERSHIP_REBALANCE = 'MEMBERSHIP_REBALANCE',
    MEMBERSHIP_PURCHASE = 'MEMBERSHIP_PURCHASE',

    /* Credit */
    USER_SIGNUP = 'USER_SIGNUP',
    ASK_AQ_REWARD = 'ASK_AQ_REWARD', // progressive profile question reward

    COMPLETE_PROFILE = 'COMPLETE_PROFILE',
    USER_FIRST_TIME_INITIATE_POSITION = 'USER_FIRST_TIME_INITIATE_POSITION',
    USER_FIRST_TIME_POSITION_CLOSE = 'USER_FIRST_TIME_POSITION_CLOSE',

    USER_NORMAL_POSITION_INITIATE = 'USER_NORMAL_POSITION_INITIATE',
    USER_NORMAL_POSITION_CLOSE = 'USER_NORMAL_POSITION_CLOSE',

    USER_POSITION_INITIATE_AFTER_2_WEEKS = 'USER_POSITION_INITIATE_AFTER_2_WEEKS',
    USER_POSITION_CLOSE_AFTER_2_WEEKS = 'USER_POSITION_CLOSE_AFTER_2_WEEKS',

    REFERRED_USER_TRADED = 'REFERRED_USER_TRADED'
}

export interface MintCoinLedger {
    transactionType: TransactionType
    units: number
    purpose: MintCoinPurpose
    comments: any
    createdAt: Date
    userInvited: InvitedUser
}

export interface MintCoinTransactions {
    data: MintCoinLedger[]
    limit: number
    offset: number
    total: number
}

export interface UserMintCoinEarning {
    summary: UserMintCoinBalance
    transactions: MintCoinTransactions
}
