/******************************
 Colors
 *******************************/

const colors = {
    BASE: {
        PRIMARY: '#202124',
        SECONDARY: '#8B8F9C',
        SUPER_LIGHT: '#F7F8FC',
        LIGHT: '#5b758d33',
        LIGHTEST: '#A9A9A9'
    },
    BRAND: {
        WHITE: '#fff',
        GREEN: '#47BC76',
        RED: '#F84C4C',
        BLUE: '#5667FF',
        BLACK: '#000',
        YELLOW: '#F0C379',
        BROWN: '#784712',
        GOLDEN: '#d79e0c',
        DARK_GREEN: '#0F394A',
        AQUA: '#ABDAED',
        PURPLE: '#5B0A58',
        DARK_BLUE: '#121685b0',
        DARKEST_BLUE: '#0A2A5B',
        WINE: '#C63535',
        COIN: '#FFC246',
        TEAL: '#2990B0',
        PALE: '#FEEFAB',
        BEIGE: '#DCCF8D',
        ORANGE: '#FF9600'
    },
    BRAND_LIGHT: {
        RED: '#FFE2E2',
        BLUE: '#EDEFFF',
        YELLOW: '#F6ED98',
        GREEN: '#DAF5E5',
        PINK: '#FBF1FF',
        GOLDEN: '#FFF7DB',
        AQUA: '#C0E0EE',
        DARK_BLUE: '#707EFF',
        WINE: '#ff6cc43b',
        COIN: '#EE9B49',
        ORANGE: '#fbebcf',
        PEACH: '#FFEEEE'
    },
    MODEL_ICON_BACKGROUND: '#fbebcf',
    HIGHLIGHT: '#FFECBC',
    SEPARATOR: '#ECEEF4',
    SEPARATOR_LIGHTEST: '#F0F0F0',

    WARNING: '#CD853F',
    WARNING_BACKGROUND: '#F084041A',
    PILLARS: {
        PURPLE: {
            PRIMARY: '#9031B1',
            SECONDARY: '#E6C7F0',
            LIGHT: '#F9ECFD',
            SUPER_LIGHT: '#FBF2FE',
            ICON: '#EBB2FF'
        },
        BROWN: {
            PRIMARY: '#9A590F',
            SECONDARY: '#FBE7D0',
            LIGHT: '#FFF6EC',
            SUPER_LIGHT: '#FFF6EC75',
            ICON: '#FDA33E'
        },
        BLUE: {
            PRIMARY: '#2990B0',
            SECONDARY: '#B1E2F1',
            LIGHT: '#E8F7FC',
            SUPER_LIGHT: '#EBF5F8',
            ICON: '#53AED2'
        },
        GREEN: {
            PRIMARY: '#5B8B02',
            SECONDARY: '#D9F0C7',
            LIGHT: '#F3FDEC',
            SUPER_LIGHT: '#F7FEF2',
            ICON: '#BDDF81'
        }
    },
    GOLDEN_COIN: '#D79E0C',
    TEMP_PINK: '#f9edfe',
    PROFILE: {
        PRIMARY: '#FEE17C',
        SECONDARY: '#FBF2FE'
    },
    TELEGRAM: {
        PRIMARY: '#144C6B',
        SECONDARY: '#C8DFE8',
        LIGHT: '#e2eef7'
    },
    MODELS_BACKGROUND: {
        YELLOW: '#ff960033',
        BEIGE: '#ad5a0d33',
        LIGHT_GREY: '#5b758d33',
        GREY: '#202c4333'
    },
    WHATSAPP: '#25D366',
    CARD_BACKGROUND: '#D7DAF3'
}

export default colors
