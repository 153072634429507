import { useQuery } from '@tanstack/react-query';

import { getCurrentUserInfo, getModelUserSubscription, getUserActions, getUserOpenPositions, getUserSubscription } from '@/services/api/user';

export const useUserSubscriptions = () => {
    const { data: userSubscription, isLoading } = useQuery({
        queryKey: ['userSubscription'],
        queryFn: () => getUserSubscription(),
    });

    return { userSubscription, isLoading };
};

export const useAllUserActions = () => {
    const { data: userAction, isLoading } = useQuery({
        queryKey: ['userAction'],
        queryFn: () => getUserActions(),
    });

    return { userAction, isLoading };
};

export const useUserOpenPosition = () => {
    const { data: userOpenPosition, isLoading } = useQuery({
        queryKey: ['getUserOpenPosition'],
        queryFn: () => getUserOpenPositions(),
    });

    return { userOpenPosition: userOpenPosition, isLoading };
};

export const useModelUserSubscription = (uuid: string) => {
    const { data: modelUserSubscription, isLoading } = useQuery({
        queryKey: ['modelUserSubscription'],
        queryFn: () => getModelUserSubscription(uuid),
    });

    return { modelUserSubscription, isLoading };
};

export const useUserInfo = () => {
    const { data: userInfo, isLoading } = useQuery({
        queryKey: ['userInfo'],
        queryFn: () => getCurrentUserInfo(),
    });

    return { userInfo, isLoading };
};
