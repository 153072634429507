import { useUserInfo } from '@/services/dataStore/reactQuery/user';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import React, { useEffect, useState } from 'react';

import { LoadingScreen } from '@/ui/container/common/loadingScreen';

import { getCookie } from '@/utils/cookie';
import { HistoryProvider } from '@/utils/hooks/routing';
import { postHogScript } from '@/utils/scriptUtils';
import { Tracking } from '@/utils/trackingUtils';
import { getBackendURL } from '@/utils/urlUtils';

import '@/styles/globals.css';
import { fetchAccessTokenAndSetHeaders } from 'Utils/fetchUtils';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
            refetchInterval: 6000000, // refetch time is very long
        },
    },
});

const useCheckPageRedirection = () => {
    const router = useRouter();
    const authToken = getCookie('authToken');
    const { pathname, asPath } = router;

    const [loadable, setLoadable] = useState(false);
    useEffect(() => {
        const isPubliclyAvailableLink = ['/', '/qr'].includes(pathname);
        const isLoginPage = pathname === '/';

        if (!authToken && !isPubliclyAvailableLink) {
            // redirect if url is not /
            if (pathname !== '/') {
                router.push(`/?redirect=${asPath}`);
            }
        }

        if (!!authToken && isLoginPage) {
            router.push(`/dashboard`);
        }
        setLoadable(true);
    }, []);

    return { loadable };
};

export const Wrapper = () => {
    const { userInfo } = useUserInfo();

    useEffect(() => {
        const userId = userInfo?.uuid;
        if (!!userId) {
            Tracking.identifyUser(userId);
        }
    }, [userInfo]);
    return null;
};

export default function NextApp({ Component, pageProps }: AppProps) {
    const { loadable } = useCheckPageRedirection();

    useEffect(() => {
        Tracking.initMixpanel();
    }, []);

    if (!loadable) return <LoadingScreen />;
    fetchAccessTokenAndSetHeaders();
    return (
        <React.Fragment>
            <style jsx global>{`
                html,
                body {
                    font-family: 'Plus Jakarta Sans', serif;
                }
            `}</style>
            <script dangerouslySetInnerHTML={{ __html: postHogScript }} />
            <NextNProgress color="#5667FF" />
            <QueryClientProvider client={queryClient}>
                {/*// @ts-ignore*/}
                <HistoryProvider>
                    <Wrapper />
                    <Component {...pageProps} />
                </HistoryProvider>
            </QueryClientProvider>
        </React.Fragment>
    );
}

NextApp.getInitialProps = async (nextCTX: AppContext) => {
    const pageProps = await App.getInitialProps(nextCTX);
    if (nextCTX.ctx !== undefined && nextCTX.ctx.res !== undefined) {
        nextCTX?.ctx?.res.setHeader('Cache-Control', 'public, s-maxage=1800, stale-while-revalidate=18000');
    }

    return {
        ...pageProps,
    };
};
